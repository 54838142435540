<template>
  <div id="app">
    <NavComponent />
    <main class="contenedor-general">
      <router-view/>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import NavComponent from './components/templates/NavComponent.vue';
import FooterComponent from './components/templates/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    NavComponent,
    FooterComponent
  }
}
</script>
<style lang="sass">
.contenedor-general
  max-width: 1400px
  width: 95%
  margin: 0 auto
  padding: 30px 0
  display: flex
  // min-width: 95%
  // align-items: center
  // justify-content: center
  min-height: 80vh
  .home
    width: 100%
  
</style>