<template>
  <section class="nav">
    <nav id="nav-up">
      <div class="contenedor-nav">
        <Logo />
        <Buscador />
        <MenuCategorias />
      </div>
    </nav>
    <nav id="nav-bottom">
      <div class="contenedor-nav">
        <EnlacesRapidos />
      </div>
    </nav>
  </section>
</template>

<script>
import Logo from './nav/Logo.vue';
import Buscador from '../search/Buscador.vue';
import MenuCategorias from './nav/MenuCategorias.vue';
import EnlacesRapidos from './nav/EnlacesRapidos.vue';

export default {
  name: 'NavComponent',
  components: {
    Logo,
    Buscador,
    MenuCategorias,
    EnlacesRapidos
  }
}
</script>

<style lang="sass">
#nav-up
    background: rgb(60,16,83)
    background: linear-gradient(90deg, rgba(60,16,83,1) 0%, rgba(130,70,175,1) 100%)
    height: 60px
    display: flex
    align-items: center
    justify-content: space-between
    @media screen and (min-width: 992px)
        height: 80px
    .contenedor-nav    
        max-width: 1400px
        width: 95%
        margin: 0 auto
        display: flex
        align-items: center
        justify-content: space-between
        position: relative
#nav-bottom
    background: #8246AF
    height: 40px
    display: flex
    align-items: center
    justify-content: space-between
    color: #fff
    font-size: 14px
    @media screen and (min-width: 992px)
        height: 50px
    .contenedor-nav
        max-width: 1400px
        width: 95%
        margin: 0 auto
        display: flex
        align-items: center
        justify-content: space-between
        position: relative
</style>
