<template>
<div class="complemento">
    <!-- <DireccionNav></DireccionNav> -->
    <Sesion></Sesion>
</div>
</template>

<script>
import DireccionNav from './Direccion.vue'
import Sesion from './Sesion.vue'

export default {
    name: 'MenuCategorias',
    components: {
        DireccionNav,
        Sesion
  }
}
</script>

<style lang="sass">
.complemento
    display: flex
    align-items: center
    
</style>