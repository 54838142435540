<template>
    <div>
      <h1>Favoritos</h1>
      <div v-if="!sessionActive && !favorites.length">
        No tienes productos guardados en favoritos.
      </div>
      <div v-else class="productos">
        <product-card
          v-for="(product, index) in favorites"
          :key="index"
          :product="product"
        />
      </div>
    </div>
  </template>
  <script>
  import ProductCard from '@/components/ProductCard/ProductCard.vue';
  
  export default {
    components: {
      ProductCard
    },
    data() {
      return {
        favorites: [],
        sessionActive: false
      };
    },
    methods: {
      checkSession() {
        // Simulación de una comprobación de sesión
        return new Promise(resolve => {
          setTimeout(() => {
            resolve(true); // Cambiar a false según lógica de autenticación real
          }, 1000);
        });
      },
      async loadFavoritesFromLocalStorage() {
        const favoritesData = localStorage.getItem('favorites');
        if (favoritesData) {
          this.favorites = JSON.parse(favoritesData);
        }
      },
      loadFavoritesFromServer() {
        if (!this.sessionActive) return;
        const url = 'URL_A_DEFINIR';
        const token = 'TOKEN_DEL_USUARIO';
        fetch(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => response.json())
        .then(data => {
          this.favorites = data;
        })
        .catch(error => {
          console.error('Error loading favorites from server:', error);
        });
      }
    },
    async mounted() {
      this.sessionActive = await this.checkSession();
      await this.loadFavoritesFromLocalStorage();
      if (this.sessionActive) {
        this.loadFavoritesFromServer();
      }
    }
  }
  </script>
  
  <style lang="sass" scoped>
  .productos
    display: flex
    flex-wrap: wrap
    justify-content: space-evenly
    
  </style>