<template>
  <div class="ubicacion">
        <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.58008 0C4.04492 0 0.376953 3.2865 0.376953 7.35C0.376953 12.8625 8.58008 21 8.58008 21C8.58008 21 16.7832 12.8625 16.7832 7.35C16.7832 3.2865 13.1152 0 8.58008 0ZM8.58008 9.975C6.96289 9.975 5.65039 8.799 5.65039 7.35C5.65039 5.901 6.96289 4.725 8.58008 4.725C10.1973 4.725 11.5098 5.901 11.5098 7.35C11.5098 8.799 10.1973 9.975 8.58008 9.975Z" fill="white"/>
        </svg>
        <div class="texto-direccion">
            <p class="direccion">Enviar a {direccion}</p>
            <p class="zip">CP {XXXXX}</p>
        </div>

    </div>
</template>

<script>
export default {
    name: 'DireccionNav'
}
</script>

<style lang="sass">
.ubicacion
    display: flex
    align-items: center
    font-size: 0.5em
    color: #fff
    margin-right: 1.5rem
    svg
        margin-right: 0.5rem

    .texto-direccion
        .direccion
        .zip
</style>