<template>
<div class="notificaciones">
        <span class="icono">
            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.20908 20.9775C10.4432 20.9775 11.453 20.0545 11.453 18.9263H6.96516C6.96516 20.0545 7.97492 20.9775 9.20908 20.9775ZM15.9408 14.8237V9.69549C15.9408 6.54677 14.112 3.91087 10.892 3.21344V2.516C10.892 1.66472 10.1403 0.977539 9.20908 0.977539C8.27785 0.977539 7.52614 1.66472 7.52614 2.516V3.21344C4.31733 3.91087 2.47732 6.53651 2.47732 9.69549V14.8237L0.233398 16.875V17.9006H18.1848V16.875L15.9408 14.8237ZM13.6969 15.8493H4.72124V9.69549C4.72124 7.1519 6.4154 5.0801 9.20908 5.0801C12.0028 5.0801 13.6969 7.1519 13.6969 9.69549V15.8493Z" fill="white"/>
                </svg>
                
        </span>
        <span class="txt">
            Notificaciones
        </span>
    </div>  
</template>

<script>
export default {
    name: 'Notificaciones'
}
</script>

<style lang="sass">
.notificaciones
    .txt
        display: none
        @media screen and (min-width: 1200px)
            display: inline-block

</style>