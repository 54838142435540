<template>
    <div class="mis-compras" @click="navigate">
        <span class="icono">
            <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6209 4.99999H14.7979C14.7979 3.15832 13.1664 1.66666 11.1521 1.66666C9.13773 1.66666 7.50621 3.15832 7.50621 4.99999H5.68328C4.68067 4.99999 3.86035 5.74999 3.86035 6.66666V16.6667C3.86035 17.5833 4.68067 18.3333 5.68328 18.3333H16.6209C17.6235 18.3333 18.4438 17.5833 18.4438 16.6667V6.66666C18.4438 5.74999 17.6235 4.99999 16.6209 4.99999ZM9.32914 8.33332C9.32914 8.79166 8.91898 9.16666 8.41768 9.16666C7.91637 9.16666 7.50621 8.79166 7.50621 8.33332V6.66666H9.32914V8.33332ZM11.1521 3.33332C12.1547 3.33332 12.975 4.08332 12.975 4.99999H9.32914C9.32914 4.08332 10.1495 3.33332 11.1521 3.33332ZM14.7979 8.33332C14.7979 8.79166 14.3878 9.16666 13.8865 9.16666C13.3852 9.16666 12.975 8.79166 12.975 8.33332V6.66666H14.7979V8.33332Z" fill="white"/>
            </svg>
        </span>
        <span class="txt">
            Mis compras
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MisCompras',
    computed: {
        ...mapGetters(['isAuthenticated'])
    },
    methods: {
        navigate() {
            if (this.isAuthenticated) {
                this.$router.push('/estado-pedidos');
            } else {
                this.$router.push('/iniciar-sesion');
            }
        }
    }
}
</script>

<style lang="sass">
.mis-compras 
    .txt
        display: none
        @media screen and (min-width: 1200px)
            display: inline-block
</style>
