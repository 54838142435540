<template>
    <div class="img">
      <div class="miniaturas">
        <img v-for="(img, index) in images" :key="index"
             :src="img" :alt="productName"
             :class="{ active: selectedImage === img }"
             @click="$emit('update:selectedImage', img)"
             class="thumbnail"/>
      </div>
      <div class="actual">
        <div class="contenedor-img">
          <img :src="selectedImage" :alt="productName" v-if="selectedImage" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      images: Array,
      productName: String,
      selectedImage: String
    }
  }
  </script>
  <style lang="sass" scoped>
  
.img
    width: 70%
    justify-content: space-between
    display: flex
    @media (max-width: 768px)
        width: 100%
        flex-direction: column

    .miniaturas
        width: 8%
        display: flex
        flex-direction: column
        @media (max-width: 768px)
            flex-direction: row
            overflow-x: auto
            width: 100%
            justify-content: center

        img
            border-radius: 5px
            box-shadow: 0 0 15px rgba(155, 155, 155, 0.5)
            width: 100%
            margin: 5px
            @media (max-width: 768px)
                width: 60px
                margin: 5px

        .active
            border: 3px solid #8246AF

    .actual
        width: 88%
        text-align: center
        @media (max-width: 768px)
            width: 100%

        .contenedor-img
            width: 100%
            background: #fff
            box-shadow: 0 0 15px rgba(155, 155, 155, 0.5)
            border-radius: 5px
            height: calc(70vh - 200px)
            @media (min-width: 768px)
                height: calc(80vh - 200px)
            @media (min-width: 1200px)
                height: calc(100vh - 200px)
            img
                // width: 100%
                height: 100%
                max-width: 100%
                object-fit: contain
  </style>