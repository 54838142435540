<template>
    <div class="metodos-de-pago">
      <h1>Métodos de Pago</h1>
      <p>
        En <strong>XPromo</strong>, ofrecemos diversas opciones de pago para facilitar sus compras. Nos esforzamos por brindarle una experiencia de compra segura y conveniente, aceptando múltiples métodos de pago, incluyendo PayPal, Stripe y Openpay.
      </p>
      <h2>PayPal</h2>
      <p>
        PayPal es uno de los métodos de pago más conocidos y utilizados a nivel mundial. Al seleccionar PayPal, será redirigido a su sitio web seguro para completar su pago. PayPal permite realizar transacciones utilizando su cuenta de PayPal, tarjeta de crédito o débito, sin necesidad de compartir sus datos financieros con nosotros.
      </p>
      <h2>Stripe</h2>
      <p>
        Stripe es otra opción de pago que ofrece un procesamiento seguro de tarjetas de crédito y débito. Al elegir Stripe, será redirigido a una página de pago externa donde podrá ingresar sus datos de tarjeta de forma segura. Stripe está diseñado para manejar pagos de manera rápida y segura, protegiendo su información con altos estándares de seguridad.
      </p>
      <h2>Openpay</h2>
      <p>
        Openpay es una solución de pago avanzada que permite recibir pagos con tarjetas de crédito y débito, pagos en efectivo en tiendas de conveniencia y transferencias interbancarias. Al utilizar Openpay, podrá completar sus pagos directamente en nuestro sitio web, sin necesidad de ser redirigido a un sitio externo.
      </p>
      <p>
        Openpay ofrece varias características que garantizan la seguridad y eficiencia de sus transacciones, incluyendo la prevención de fraudes en tiempo real, el uso de certificados SSL para encriptar la información transmitida y la certificación PCI-DSS para el manejo y almacenamiento de información de tarjetas. Puede conocer más sobre las características de Openpay en su <a href="https://site.openpay.mx/en/features.html" target="_blank">sitio oficial</a>.
      </p>
      <h2>Responsabilidad del Usuario</h2>
      <p>
        Al realizar pedidos que requieran la carga de imágenes para personalización, usted declara y garantiza que tiene los derechos necesarios sobre las imágenes proporcionadas y que estas no infringen los derechos de propiedad intelectual de terceros. Usted es el único responsable de cualquier reclamación relacionada con la autoría y el uso de las imágenes cargadas. Nos reservamos el derecho de rechazar cualquier imagen que consideremos inapropiada o que infrinja derechos de terceros.
      </p>
      <h2>Más Información</h2>
      <p>
        Si tiene alguna pregunta o necesita asistencia con los métodos de pago, no dude en ponerse en contacto con nosotros a través del correo electrónico <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a>.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MetodosDePago'
  }
  </script>
  
  <style lang="scss" scoped>
  .metodos-de-pago {
    padding: 40px 20px;
        max-width: 1200px;
        margin: 0 auto;
        background-color: #f9f9f9;
        border-radius: 8px;
        font-size: 0.8em;
    
        h1 {
        font-size: 2em;
        text-align: center;
        margin-bottom: 10px;
        }
        h2 {
            text-align: center;
            margin: 30px auto 10px auto;
        }
        p {
        font-size: 0.9em;
        text-align: justify;
        line-height: 1.5;
        }
        a {
        color: #007BFF;
        text-decoration: none;

        &:hover {
        text-decoration: underline;
        }
    }
  }
  </style>
  