<template>
    <div class="carrito" @click="goToCart">
        <span class="icono">
            <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.46256 16.8485C6.26899 16.8485 5.30328 17.7412 5.30328 18.8323C5.30328 19.9235 6.26899 20.8162 7.46256 20.8162C8.65614 20.8162 9.6327 19.9235 9.6327 18.8323C9.6327 17.7412 8.65614 16.8485 7.46256 16.8485ZM18.3133 16.8485C17.1197 16.8485 16.154 17.7412 16.154 18.8323C16.154 19.9235 17.1197 20.8162 18.3133 20.8162C19.5068 20.8162 20.4834 19.9235 20.4834 18.8323C20.4834 17.7412 19.5068 16.8485 18.3133 16.8485ZM7.64703 13.6247L7.67958 13.5057L8.65614 11.8888H16.7399C17.5537 11.8888 18.2699 11.4821 18.6388 10.8671L22.8271 3.91366L20.9391 2.9614H20.9283L19.7347 4.94527L16.7399 9.90493H9.12272L8.98166 9.63711L6.55111 4.94527L5.52029 2.9614L4.50033 0.977539H0.952148V2.9614H3.12229L7.02854 10.4902L5.56369 12.9204C5.39008 13.1982 5.29243 13.5255 5.29243 13.8727C5.29243 14.9638 6.26899 15.8565 7.46256 15.8565H20.4834V13.8727H7.91829C7.77724 13.8727 7.64703 13.7636 7.64703 13.6247Z" fill="white"/>
            </svg>
            <div class="cart-count" v-if="cartProductCount > 0">
                {{ cartProductCountDisplay }}
            </div>
        </span>
        <span class="txt">
            Carrito
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Carrito',
    computed: {
        ...mapGetters(['cartItems']),
        cartProductCount() {
            return new Set(this.cartItems.map(item => item.id)).size;
        },
        cartProductCountDisplay() {
            const count = this.cartProductCount;
            return count > 9 ? '+9' : count.toString();
        }
    },
    methods: {
        goToCart() {
            this.$router.push('/mi-carrito');
        }
    }
}
</script>

<style lang="sass">
.carrito
    cursor: pointer
    position: relative
    .icono
        position: relative
    .cart-count
        position: absolute
        top: -10px
        right: -10px
        background-color: red
        color: white
        border-radius: 50%
        width: 20px
        height: 20px
        display: flex
        align-items: center
        justify-content: center
        font-size: 12px
    .txt
        display: none
        @media screen and (min-width: 1200px)
            display: inline-block
</style>
