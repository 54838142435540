<template>
<EnlacesBottom></EnlacesBottom>
<AccesosRapidos></AccesosRapidos>
             
</template>

<script>
import EnlacesBottom from './EnlacesBottom.vue'
import AccesosRapidos from './AccesosRapidos.vue'
export default {
    name: 'EnlacesRapidos',
    components: {
        EnlacesBottom,
        AccesosRapidos

    }    
}
</script>

<style lang="sass">

</style>