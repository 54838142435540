<template>
    <div v-if="orderSuccess" class="resumen-compra">
      <div class="success">

        <p>COTIZACIÓN GENERADA</p>
        <svg width="119" height="119" viewBox="0 0 119 119" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="59.5" cy="59.5" r="59.5" fill="#6BBC69"/>
        <path d="M83.4862 38.4328L87.6279 42.6045L49.9387 80.5672L33.3721 63.8803L37.5138 59.7086L49.9387 72.2237L83.4862 38.4328ZM83.4862 30L49.9387 63.7909L37.5138 51.2758L25 63.8803L49.9387 89L96 42.6045L83.4862 30Z" fill="white"/>
        </svg>
        <p>ESTAMOS VERIFICANDO TU PEDIDO</p>
        <span>Folio: <b id="idFolio">{{ orderFolio }}</b></span>

        <button @click="goToOrders">Ir a mis pedidos</button>
      </div>
    </div>    
    <div v-else class="resumen-compra">
      <h3>Resumen de Compra</h3>
      <div class="contenido-resumen">
        <div class="total-productos">
        <span>Productos ({{ totalProductos }})</span>
        <span>${{ totalSinDescuento.toFixed(2) }}</span>
      </div>
    
        <!-- <div class="descuento">
            <label for="cupon">Ingresar código de cupón:</label>
            <div class="cupon">
                <input type="text" id="cupon" v-model="cupon">
                <a @click="aplicarCupon">Validar cupón</a>

            </div>
        </div> -->
     
       <!-- Mostrar costos de terminado solo si hay productos con terminado -->
       <div v-if="costoTerminado > 0" class="total-final">
        <span>Costo Terminado</span>
        <span>${{ costoTerminado.toFixed(2) }}</span>
      </div>
      <div class="total-final">
        <span>Total</span>
        <span><b>${{ totalFinal.toFixed(2) }}</b></span>
      </div>
        <button v-if="this.pasoActual === 1 || (this.pasoActual === 2 && this.addressLocked) || this.pasoActual === 3" class="conitnuar" @click="$emit('continuar')">Continuar</button>
      </div>
    </div>
  </template>
<script>
  export default {
    props: {
      totalProductos: {
        type: Number,
        required: true,
        default: 0, // Proporciona un valor predeterminado
        
      },
      pasoActual: {
        type: Number,
        required: true
      },      
      costoTerminado: Number,
      addressLocked: Boolean,
      orderSuccess: Boolean,
      totalProductos: Number,
      totalCosto: Number,
      orderFolio: String
    },
    data() {
      return {
        cupon: '',
        descuento: 0,
        // Establece el costo fijo de terminado por producto
        costoTerminadoPorProducto: 4000,
      };
    },
    computed: {

      totalSinDescuento() {
      // Asegurarse de que totalCosto es un número
        return parseFloat(this.totalCosto) || 0;
      },
      costoTerminado() {
        const productosConTerminado = this.$store.getters.cartItems.filter(item => item.technique !== 'Sin terminado').length;
        console.log(productosConTerminado)
        console.log(this.costoTerminadoPorProducto)
        console.log(productosConTerminado * this.costoTerminadoPorProducto)
        return productosConTerminado * this.costoTerminadoPorProducto;  // Esto siempre será un número
      },
      totalFinal() {
        const productosConTerminado = this.$store.getters.cartItems.filter(item => item.technique !== 'Sin terminado').length;
        const costoTerminado = productosConTerminado * this.costoTerminadoPorProducto;  // Esto siempre será un número
        const total = (parseFloat(this.totalSinDescuento) + costoTerminado - parseFloat(this.descuento)) || 0;
        return total;
      }
    },
    methods: {
      emitTotalCost() {
        this.$emit('updateTotalCost', this.totalFinal()); // Asegúrate de que totalFinal es un método o una propiedad computada que retorna el valor correcto
      },
      aplicarCupon() {
        if (this.cupon === 'DESCUENTO10') {
          this.descuento = 10;
          alert('Cupón aplicado correctamente');
        } else {
          alert('Cupón no válido');
        }
        this.$emit('aplicarCupon', this.cupon);
      },
      goToOrders() {
        this.$router.push('/estado-pedidos');
      }
    }
  }
</script>  
<style lang="sass" scoped>
.resumen-compra 
  border: 1px solid #ccc
  border-radius: 8px  
  margin: 20px 0
  border-radius: 15px
  overflow: hidden
  background: #fff 
  h3     
    font-size: 22px
    font-weight: lighter
    background: #8246AF
    padding: 8px 15px
    color: #fff
  div 
    margin-bottom: 10px  
  
.success 
  padding: 30px 30px 10px 30px
  text-align: center
  p 
    color: #8246AF
    font-size: 0.7em
    margin: 0 0 15px 0
    font-weight: bold
  span
    color:#333
    font-size: 0.8em
    margin-bottom: 20px
    display: block

.contenido-resumen 
  padding: 20px
  font-size: 19px
  .total-productos
    color: #8246AF
    display: flex
    justify-content: space-between
    .descuento 
      label
        font-size: 16px
      input
        background: #EDEDED
        border: none
        width: 50%
        border-radius: 5px
      .cupon 
        a
          font-size: 14px
          margin-left: 10px
          color: #2F80ED
          cursor: pointer
  .total-final
    color: #8246AF
    display: flex
    justify-content: space-between
    .total
      font-weight: bold
    .number-total
      color: #000
button 
  background-color: #8246AF
  border: none
  color: white
  padding: 10px 20px
  text-align: center
  text-decoration: none
  display: inline-block
  font-size: 16px  
  cursor: pointer
  width: 100%
  border-radius: 30px
  &:hover 
    background-color: #965ec1  
  
input[type=text] 
  padding: 10px
  margin-top: 5px
  margin-bottom: 20px
  border: 1px solid #ccc
  border-radius: 4px

</style>
  