<template>
    <div class="contacto">
      <h1>Contacto</h1>
      <div class="info-container">
        <div class="info-block">
          <h2>Contáctanos</h2>
          <p><i class="fas fa-phone"></i> (55) 3626 0230</p>
          <p><i class="fas fa-phone"></i> (55) 3626 0231</p>
          <p><i class="fas fa-whatsapp"></i> (+52) 55766 87064</p>
          <p><i class="fas fa-envelope"></i> <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a></p>
        </div>
        <div class="info-block">
          <h2>Horario de atención</h2>
          <p>Lun - Mie: 9:00 - 19:00</p>
          <p>Jueves: 9:00 - 18:00</p>
          <p>Viernes: 9:00 - 14:00</p>
        </div>
        <div class="info-block">
          <h2>Dirección</h2>
          <p>Tiziano #77 Col. Alfonso XIII</p>
          <p>Alvaro Obregón, Ciudad de México</p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.7488285115795!2d-99.20317368509453!3d19.37553778691717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fffe6f99e807%3A0x1009f4b6b84b4e45!2sTiziano%2077%2C%20Alfonso%20XIII%2C%20%C3%81lvaro%20Obreg%C3%B3n%2C%2001450%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX%2C%20Mexico!5e0!3m2!1sen!2sus!4v1598667409345!5m2!1sen!2sus" width="100%" height="200" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
      </div>
      <div class="formulario-contacto">
        <h2>Formulario de Contacto</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="nombre">Nombre</label>
            <input type="text" id="nombre" v-model="form.nombre" required />
          </div>
          <div class="form-group">
            <label for="email">Correo Electrónico</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>
          <div class="form-group">
            <label for="mensaje">Mensaje</label>
            <textarea id="mensaje" v-model="form.mensaje" required></textarea>
          </div>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useToast } from "vue-toastification";
  
  export default {
    name: 'Contacto',
    setup() {
      // Get toast interface
      const toast = useToast();          
      return { toast }
    },
    data() {        
      return {
        form: {
          nombre: '',
          email: '',
          mensaje: ''
        }
      }
    },
    methods: {
      async submitForm() {
          try {
            const response = await axios.post('/api/contacto-nuevo', this.form);
            this.toast.success("Mensaje enviado con éxito");
            this.form.nombre = '';
            this.form.email = '';
            this.form.mensaje = '';
        } catch (error) {
          console.error('Error enviando el formulario:', error);
          this.toast.error('Hubo un error al enviar su mensaje. Por favor, inténtelo de nuevo más tarde.');
        }
      }
    }
  }
  </script>
  
  <style lang="sass" scoped>
  .contacto
    padding: 20px
    max-width: 1200px
    width: 95vw
    margin: 0 auto    
    border-radius: 8px
    font-size: 0.7em
    h1
      margin: 20px 0
  
    .info-container
      display: flex
      justify-content: space-between
      margin-bottom: 40px
  
      .info-block
        flex: 1
        margin-right: 20px
        &:last-child
          margin-right: 0
  
        h2
          font-size: 1.8em
          margin-bottom: 10px
  
        p
          font-size: 1.2em
          line-height: 1.5
          display: flex
          align-items: center
  
          i
            margin-right: 10px
  
          a
            color: #007BFF
            text-decoration: none
  
            &:hover
              text-decoration: underline
  
        iframe
          margin-top: 10px
          border: none
  
    .formulario-contacto
      background-color: #f9f9f9
      padding: 20px
      border-radius: 20px
      h2
        font-size: 1.8em
        margin: 20px auto
  
      .form-group
        margin-bottom: 15px
  
        label
          display: block
          margin-bottom: 5px
          font-weight: bold
  
        input, textarea
          width: 100%
          padding: 8px
          border: 1px solid #ccc
          border-radius: 4px
          font-size: 1em
  
        textarea
          resize: vertical
  
      button
        padding: 10px 15px
        background-color: #007BFF
        color: white
        border: none
        border-radius: 4px
        cursor: pointer
  
        &:hover
          background-color: #0056b3
  </style>
  