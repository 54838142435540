<template>
  <div class="home">      
    <BennerHome/>
    <div class="cuadros">
      <CuadroCuatroFavoritos/>
      <CuadroCuatroPodriaInteresarte/>
      <CuadroCuatroHistorial/>
      <CuadroCuatroMasVendidos/>
    </div>
    <div v-if="loading" class="loading">Cargando...</div>
    <div v-else class="productos">  
      <ProductCard v-for="product in products" :key="product.id" :product="product" />
    </div>      
    <Category />
      
  </div>
</template>

<script>
import axios from 'axios';
import Category from '@/components/categorias/Category.vue';
import ProductCard from '@/components/ProductCard/ProductCard.vue';
import BennerHome from '@/components/BennerHome/BennerHome.vue';

import CuadroCuatroFavoritos from '@/components/CuatroCruadosHome/CuadroCuatroFavoritos.vue'
import CuadroCuatroHistorial from '@/components/CuatroCruadosHome/CuadroCuatroHistorial.vue'
import CuadroCuatroMasVendidos from '@/components/CuatroCruadosHome/CuadroCuatroMasVendidos.vue'
import CuadroCuatroPodriaInteresarte from '@/components/CuatroCruadosHome/CuadroCuatroPodriaInteresarte.vue'

export default {
  name: 'Home',
  components: {
    Category,
    ProductCard,
    BennerHome,
    CuadroCuatroFavoritos,
    CuadroCuatroHistorial,
    CuadroCuatroMasVendidos,
    CuadroCuatroPodriaInteresarte
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      products: [],
      loading: true
    }
  },

  created() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      axios.get('/api/random-products')
        .then(response => {
          this.products = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching products:', error);
          this.loading = false;
        });
    },
    goToProduct(idProductoProv) {
      this.$router.push(`/producto/${idProductoProv}`);
    }
  }
}
</script>

<style lang="sass" scoped>

.loading 
  font-size: 20px
  color: #333
  text-align: center
  padding: 20px

.productos
  display: flex
  flex-wrap: wrap
  justify-content: space-between
.cuadros
  display: flex
  justify-content: space-between 
  flex-wrap: wrap

</style>
