<template>
    <p class="txt-detalle">{{ descripcion }}</p>
</template>
<script>
export default {
    props: {
        descripcion : {
            type: String,
            required: true
        }
    },
}
</script>

<style lang="sass" scoped>
.txt-detalle
    font-size: 0.5em
    margin: 0 0 5px 0
</style>