<template>
    <div class="politicas-envios">
      <h1>Políticas de Envíos</h1>
      <p>
        En <strong>XPromo</strong>, nos esforzamos por ofrecer un servicio de envío rápido y seguro para garantizar que reciba sus productos en el menor tiempo posible. Nuestras políticas de envío están diseñadas para cumplir con las leyes y regulaciones vigentes en México.
      </p>
      <h2>Tiempo de Procesamiento</h2>
      <p>
        Todos los pedidos se procesan dentro de 1 a 3 días hábiles. Los pedidos no se envían ni se entregan los fines de semana o días festivos. Si experimentamos un alto volumen de pedidos, los envíos pueden retrasarse. Por favor, permita días adicionales en tránsito para la entrega. Si habrá un retraso significativo en el envío de su pedido, nos pondremos en contacto con usted por correo electrónico o teléfono.
      </p>
      <h2>Costos de Envío y Estimaciones de Entrega</h2>
      <p>
        Los costos de envío de su pedido se calcularán y se mostrarán al finalizar la compra. Las estimaciones de entrega varían según la ubicación y el método de envío seleccionado:
      </p>
      <ul>
        <li>Envío estándar: 3-7 días hábiles</li>
        <li>Envío express: 1-3 días hábiles</li>
      </ul>
      <p>
        Tenga en cuenta que los tiempos de entrega son estimaciones y pueden variar debido a factores fuera de nuestro control, como retrasos por parte de la empresa de mensajería o situaciones excepcionales.
      </p>
      <h2>Confirmación de Envío y Seguimiento de Pedidos</h2>
      <p>
        Recibirá un correo electrónico de confirmación de envío una vez que su pedido haya sido enviado, que incluirá su(s) número(s) de seguimiento. El número de seguimiento estará activo dentro de las 24 horas.
      </p>
      <h2>Aduanas, Aranceles e Impuestos</h2>
      <p>
        <strong>XPromo</strong> no es responsable de las aduanas y los impuestos aplicados a su pedido. Todos los cargos impuestos durante o después del envío son responsabilidad del cliente (aranceles, impuestos, etc.).
      </p>
      <h2>Daños y Pérdidas</h2>
      <p>
        <strong>XPromo</strong> no se hace responsable de los productos dañados o perdidos durante el envío. Si recibió su pedido dañado, comuníquese con la empresa de mensajería para presentar una reclamación. Guarde todos los materiales de embalaje y los productos dañados antes de presentar una reclamación.
      </p>
      <h2>Política de Envío Internacional</h2>
      <p>
        Actualmente, no realizamos envíos fuera de México.
      </p>
      <h2>Legislación Aplicable</h2>
      <p>
        Estas políticas están alineadas con las regulaciones de la Ley Federal de Protección al Consumidor de México. Le recomendamos que revise esta ley para conocer sus derechos como consumidor.
      </p>
      <h2>Contacto</h2>
      <p>
        Si tiene alguna pregunta sobre nuestras políticas de envío, no dude en ponerse en contacto con nosotros a través de <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a>.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PoliticasEnvios'
  }
  </script>
  
  <style lang="scss" scoped>
  .politicas-envios {
    padding: 40px 20px;
        max-width: 1200px;
        margin: 0 auto;
        background-color: #f9f9f9;
        border-radius: 8px;
        font-size: 0.8em;
    
        h1 {
        font-size: 2em;
        text-align: center;
        margin-bottom: 10px;
        }
        h2 {
            text-align: center;
            margin: 30px auto 10px auto;
        }
        ul {
            list-style: none;
            padding-left: 30px;
        }
        li {
            font-size: 0.8em;
            margin: 10px 0;
        }
        p {
        font-size: 0.9em;
        text-align: justify;
        line-height: 1.5;
        }
        a {
        color: #007BFF;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
  }
  </style>
  