<template>
  <div>
    <h1>Resultados de búsqueda</h1>
    <div v-if="loading" class="loading">Cargando...</div>
    <div v-else class="productos">
      <ProductCard v-for="product in results" :key="product.id" :product="product" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ProductCard from '@/components/ProductCard/ProductCard.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      results: [],
      loading: true
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    ProductCard
  },
  computed: {
    ...mapGetters(['imageSearchResults'])
  },
  watch: {
    imageSearchResults: {
      immediate: true,
      handler(newResults) {
        if (newResults.length > 0) {
          this.results = newResults;
          this.loading = false;
        }
      }
    },
    '$route.query.searchTerm': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== 'busqueda-inteligente' && newVal !== oldVal) {
          this.fetchResults();
        }
      }
    }
  },
  methods: {
    async fetchResults() {
      const searchTerm = this.$route.query.searchTerm;
      console.log('Búsqueda actual:', searchTerm);
      if (searchTerm !== 'busqueda-inteligente') {
        this.loading = true;
        try {
          const response = await axios.get(`/api/products/search/${searchTerm}`);
          this.results = response.data;
          this.loading = false;
        } catch (error) {
          console.error('Error al obtener los resultados:', error);
          this.loading = false;
        }
      }
    }, 
  }
}
</script>


<style lang="sass" scoped>
.loading 
  font-size: 20px
  color: #333
  text-align: center
  padding: 20px

.productos
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  
</style>
