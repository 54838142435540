<template>
    <div class="pago">
      <p class="titulo">Procesar Pago para Orden <span>{{ tokenpedido }}</span></p>
      <div class="total">
        <p>Total a pagar:<b> $ {{ totalAmount }}</b></p>
      </div>
      
      <!-- Botones de selección de método de pago -->
      <div class="opciones-pago">
        <button 
          :class="['button-ver', { 'active': showCardForm, 'inactive': !showCardForm }]" 
          @click="payWithCard"
        >
          Pagar con Tarjeta de Crédito/Débito
        </button>
        <button 
          :class="['button-ver', { 'active': showTransferDetails, 'inactive': !showTransferDetails }]" 
          @click="payWithTransfer"
        >
          Pagar por Transferencia Bancaria
        </button>
      </div>
  
      <!-- Si selecciona tarjeta, muestra el formulario -->
    
    <div v-if="showCardForm" class="formulario-tarjeta">
        <form id="formOpenPay" @submit.prevent="processCardPayment">
            <!-- Campos de entrada para la tarjeta -->
            <div class="form-group">
            <input type="text" v-model="cardNumber" placeholder="Número de tarjeta" required />
            </div>
            <div class="form-group">
            <input type="text" v-model="holderName" placeholder="Nombre del titular" required />
            </div>
            <div class="form-group mes">
            <input type="number" v-model="expirationMonth" placeholder="Mes de expiración (MM)" required />
            <input type="number" v-model="expirationYear" placeholder="Año de expiración (YY)" required />
            <input type="number" v-model="cvv" placeholder="CVV" required />
            </div>
            

             <!-- Campos de dirección -->
            <div class="form-group">
            <input type="text" v-model="cityEstado" placeholder="Estado" required />
            </div>
            <div class="form-group">
            <input type="text" v-model="cp" placeholder="Código Postal" required />
            </div>
            <div class="form-group">
            <input type="text" v-model="colonia" placeholder="Colonia" required />
            </div>
            <div class="form-group">
            <input type="text" v-model="calle" placeholder="Calle" required />
            </div>
            <div class="form-group">
            <input type="text" v-model="numExterior" placeholder="Número Exterior" required />
            </div>
            <!-- Campo de sesión de dispositivo -->
            <input type="hidden" id="deviceIdHiddenFieldName" v-model="deviceSessionId" />

            <button type="submit" class="button-ver">Realizar Pago</button>
        </form>
    </div>

  
      <!-- Si selecciona transferencia, muestra el detalle de transferencia -->
      <div v-if="showTransferDetails" class="transferencia-bancaria">
        <p>Por favor realice su pago a la siguiente cuenta bancaria:</p>
        <div v-if="transferData.length === 1">
            <ul>
            <li><b>Banco:</b> {{ transferData[0].banco }}</li>
            <li><b>Cuenta:</b> {{ transferData[0].cuenta }}</li>
            <li><b>CLABE:</b> {{ transferData[0].clabe }}</li>
            <li><b>Beneficiario:</b> {{ transferData[0].beneficiario }}</li>
            </ul>
        </div>
        <div v-else>
            <div class="botones-bancos">
            <button
                v-for="(transferencia, index) in transferData"
                :key="index"
                @click="showTransferDetailsFor(index)"
                :class="['button-banco', { 'active': selectedBankIndex === index }]"
            >
                {{ transferencia.banco }}
            </button>
            </div>

            <!-- Mostrar los detalles del banco seleccionado -->
            <div v-if="selectedBankIndex !== null" class="detalles-banco">
            <ul>
                <li><b>Banco:</b> {{ transferData[selectedBankIndex].banco }}</li>
                <li><b>Cuenta:</b> {{ transferData[selectedBankIndex].cuenta }}</li>
                <li><b>CLABE:</b> {{ transferData[selectedBankIndex].clabe }}</li>
                <li><b>Beneficiario:</b> {{ transferData[selectedBankIndex].beneficiario }}</li>
            </ul>
            </div>
        </div>
        <p>Una vez realizado el pago, suba su comprobante en el siguiente formulario:</p>
        <form @submit.prevent="uploadReceipt">
            <div class="file-upload">
            <input type="file" ref="fileInput" @change="onFileChange" hidden />
            <button type="button" @click="selectFile" class="button-ver comprobante">
                {{ uploadButtonText }}
            </button>
            <div v-if="receiptFile" class="file-info">
                <span>{{ receiptFile.name }}</span>
                <button type="button" @click="removeFile" class="remove-file">✖</button>
            </div>
            </div>
            <button type="submit" class="button-ver" :disabled="!receiptFile">Subir Comprobante</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
import axios from "axios";

export default {
  data() {
    return {
      tokenpedido: null,
      totalAmount: 0,
      showCardForm: true,
      showTransferDetails: false,
      receiptFile: null,
      uploadButtonText: "Seleccionar comprobante",
      transferData: [], // Para almacenar los datos de la transferencia
      selectedBankIndex: null, // Índice del banco seleccionado
      // Campos del formulario de tarjeta
      cardNumber: "",
      holderName: "",
      expirationMonth: "",
      expirationYear: "",
      cvv: "",
      deviceSessionId: "",
      // Datos adicionales para la tarjeta
      cityEstado: "",
      cp: "",
      colonia: "",
      calle: "",
      numExterior: "",
      email: "",
      cliente: "",
    };
  },
//   created() {
//     this.tokenpedido = this.$route.params.tokenpedido;
//     this.fetchOrderDetails();
//   },
  async created() {
    // Cargar scripts de OpenPay dinámicamente
    await this.loadOpenPayScript("https://js.openpay.mx/openpay.v1.min.js");
    await this.loadOpenPayScript("https://js.openpay.mx/openpay-data.v1.min.js");

    // Configurar OpenPay
    OpenPay.setId("msp4sntjatft37kv5dln");
    OpenPay.setApiKey("pk_14de43ff2bd54bb39687ade99b030f2a");
    OpenPay.setSandboxMode(true);

    // Generar el ID de sesión del dispositivo
    this.deviceSessionId = OpenPay.deviceData.setup("formOpenPay", "deviceIdHiddenFieldName");
    this.tokenpedido = this.$route.params.tokenpedido;
    this.fetchOrderDetails();
  },
  methods: {
    loadOpenPayScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = resolve;
        document.head.appendChild(script);
      });
    },
    fetchOrderDetails() {
      const token = this.$store.state.user.token;
      axios
        .post('/api/orden/detalle', {
          user_token: token,
          order_token: this.tokenpedido
        })
        .then((response) => {
          this.totalAmount = response.data.totalAmount;
        })
        .catch((error) => {
          console.error("Error al obtener los detalles de la orden:", error);
        });
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    payWithCard() {
      this.showCardForm = true;
      this.showTransferDetails = false;
    },
    payWithTransfer() {
      this.showTransferDetails = true;
      this.showCardForm = false;
      this.fetchTransferData(); // Llamar a la función para obtener los datos
    },
    fetchTransferData() {
      axios
        .get('/api/datos-transferencia') // Llamada al backend para obtener los datos
        .then((response) => {
          this.transferData = response.data; // Almacena los datos recibidos
        })
        .catch((error) => {
          console.error("Error al obtener los datos de transferencia:", error);
        });
    },
    showTransferDetailsFor(index) {
      this.selectedBankIndex = index;
    },
    processCardPayment() {
      // Crear token de tarjeta con OpenPay
      const cardRequest = {
        holder_name: this.holderName,
        card_number: this.cardNumber,
        cvv2: this.cvv,
        expiration_month: this.expirationMonth,
        expiration_year: this.expirationYear,
        device_session_id: this.deviceSessionId,
        address: {
          line1: this.numExterior,
          line2: this.calle,
          line3: this.colonia,
          state: this.cityEstado,
          city: this.cityEstado,
          postal_code: this.cp,
          country_code: "MX",
        },
      };

      OpenPay.token.create(
        cardRequest,
        (response) => {
          // Token de la tarjeta generado exitosamente
          const tokenCard = response.data.id;
          const userToken = this.$store.state.user.token;

          // Enviar token de tarjeta y sessionId al backend
          axios
            .post("/api/generar-pago", {
              user_token: userToken,
              order_token: this.tokenpedido,
              tokenCard: tokenCard,
              device_session_id: this.deviceSessionId,
            })
            .then((response) => {
              if (response.data.success) {
                alert("Pago procesado con éxito");
              } else {
                alert("Error al procesar el pago");
              }
            })
            .catch((error) => {
              console.error("Error procesando el pago:", error);
            });
        },
        (error) => {
          // Error al generar el token
          console.error("Error al generar token: " + error.data.description);
        }
      );
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.receiptFile = file;
        this.uploadButtonText = "Cambiar comprobante";
      }
    },
    removeFile() {
      this.receiptFile = null;
      this.uploadButtonText = "Seleccionar comprobante";
      this.$refs.fileInput.value = ""; // Reiniciar el input de archivo
    },
    uploadReceipt() {
      const formData = new FormData();
      formData.append("receipt", this.receiptFile);
      formData.append("order_id", this.tokenpedido);

      axios
        .post("/api/subir-comprobante", formData)
        .then((response) => {
          if (response.data.success) {
            alert("Comprobante subido con éxito");
          } else {
            alert("Error al subir el comprobante");
          }
        })
        .catch((error) => {
          console.error("Error al subir el comprobante:", error);
        });
    }
  }
};
</script>

  
<style lang="sass" scoped>

.pago
    width: 90vw
    max-width: 600px
    margin: 40px auto 0 auto
    .titulo
        text-align: center
        margin-bottom: 15px
        // font-size: 1.8em
        span
            font-weight: 700
            color: #3c1053
    .total
        margin-bottom: 15px
        text-align: center
        b
            color: #3c1053

    .opciones-pago
        display: flex
        justify-content: space-around
        margin: 0 auto 30px auto
        button
            padding: 20px 25px

.button-ver
    background-color: #3c1053
    color: white
    padding: 10px 20px
    border-radius: 30px
    margin-top: 10px
    cursor: pointer
    transition: all 0.2s

.button-ver:hover
    background-color: #924bb7

/* Botones de método de pago */
.active
    background-color: #3c1053
    color: white

.inactive
    background-color: transparent
    color: #3c1053
    border: 2px solid #3c1053

.inactive:hover
    background-color: rgba(60, 16, 83, 0.1)

.formulario-tarjeta,
.transferencia-bancaria
    border: 1px solid #EEF2F7
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04)
    border-radius: 1.6rem
    padding: 24px
    margin-bottom: 2rem
    position: relative


.form-group
    margin-bottom: 15px
    input[type="text"],
    input[type="number"]
        width: 100%
        padding: 10px
        border-radius: 8px
        border: 1px solid #ccc
        box-sizing: border-box
        background: #EEF2F7
        transition: border 0.3s ease
.form-group.mes 
    display: flex
    justify-content: space-between
    margin-bottom: 30px
    input
        width: 31%
.transferencia-bancaria
    ul
        list-style-type: none
        padding: 0

    ul li
        margin-bottom: 10px

.detalles-compra
    display: flex
    flex-direction: column
    justify-content: center
    padding: 24px
    .detalles-planes
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        margin: 2rem auto
        h1
            width: 100%
            text-align: center
            margin-bottom: 2rem
        .container-plan
            padding: 2rem
            width: 100%
            @media (min-width: 576px)
                width: 50%
            @media (min-width: 992px)
                width: 33.3%
            .plan
                padding: 1rem
                border-radius: 20px
                box-shadow: 0 0 10px rgba(155, 155, 155, 0.3)
                transition: all 0.3s
                text-align: center
                display: flex
                flex-direction: column
                align-content: space-between
                &:hover
                    transform: scale(1.05)
                    box-shadow: 5px 5px 20px rgba(155, 155, 155, 0.5)
                .titulo-plan
                    margin: 1rem auto 2rem auto
                    font-size: 1.5em
                .detalles-plan
                    text-align: left
                    margin-bottom: 2rem
                    p
                        margin-bottom: 1rem
                .costo-plan
                    text-align: left
                .seleccionar-plan
                    display: inline-block
                    color: #fff
                    background: #755BD7
                    padding: 1rem 4rem
                    border-radius: 10px
                    transition: all 0.2s
                    margin: 2rem auto 1rem auto
                    cursor: pointer
                    &:hover
                        box-shadow: 0 0 20px rgba(155, 155, 155, 0.6)
                        transform: scale(1.05)
                    &:active
                        transform: scale(0.9)

.detalles-card
    max-width: 50rem
    margin: 1.8rem auto
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    .input
        position: relative
        input
            padding: 22px 16px
            border: 1px solid #EEF2F7
            border-radius: 8px
            background: #EEF2F7
            width: 100%
        span
            position: absolute
            top: 0
            bottom: 0
            right: 3.5rem
            margin: auto
            svg
                position: absolute
                right: 1rem
                top: 0
                bottom: 0
                margin: auto

.complemento
    width: 100%
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    .input
        width: 49%
        margin: 1rem 0
        input
            width: 100%

.formulario-envio
    display: none
    width: 100%
    margin-bottom: 6rem
    button
        display: block
        background: #755BD7
        color: #fff
        width: 100%
        max-width: 60rem
        margin: 0 auto
        padding: 1.5rem
        text-align: center
        border-radius: 0.8rem
        border: none
        cursor: pointer
        transition: all 0.3s
        &:hover
            box-shadow: 5px 5px 10px rgba(155, 155, 155, 0.4)
        &:active
            transform: scale(0.95)
.formulario-tarjeta
    background: #fff
.transferencia-bancaria 
    form
        text-align: right
    background: #fff
    p 
        font-size: 0.8em
    .botones-bancos
        display: flex
        justify-content: space-around
        padding: 20px 0
        .button-banco 
            padding: 5px 20px
            border-radius: 10px
            background: transparent
        .button-banco.active 
            background: #3c1053
            
    .file-upload 
        display: flex
        align-items: center
        flex-direction: column
        gap: 10px
        margin-bottom: 10px
    .file-info 
        display: flex
        align-items: center
        background-color: #f3f3f3
        padding: 5px 10px
        border-radius: 4px
        font-size: 0.6em
    .file-info 
        span 
            margin-right: 8px
    .remove-file 
        background: none
        border: none
        color: #d9534f
        cursor: pointer
        font-size: 1.2em
        line-height: 1
    .button-ver 
        padding: 10px 20px
        border-radius: 30px
        cursor: pointer
        transition: background-color 0.3s
    .button-ver.comprobante
        background: #a07fb1
    .button-ver:disabled 
        background-color: #cccccc
        cursor: not-allowed


</style>
