<template>
    <div class="resumen-compra">
        <h3>MIS COMPRAS</h3>
        <div v-if="orders.length === 0">
            No hay órdenes disponibles.
        </div>
        <div v-for="(order, index) in orders" :key="index" class="order">
            <h4>Orden ID: {{ order.id_token_order }}</h4>
            <p><b>Total:</b> $ {{ order.total_amount }}</p>
            <p><b>Estado:</b> {{ order.status }}</p>
            
            <div v-for="(item, itemIndex) in order.items" :key="itemIndex" class="item">
                <div class="imagen">
                    <img :src="item.imagen" alt="producto"/>
                </div>
                <div class="data">
                    <p class="txt-morado txt-bold">{{ item.nombre }}</p>
                    <div class="campo">
                        <div class="label txt-morado"><p>Cantidad:</p></div>
                        <div class="info"><p>{{ item.quantity }}</p></div>
                    </div>
                    <div class="campo">
                        <div class="label txt-morado"><p>Terminado:</p></div>
                        <div class="info"><p>{{ item.tecnica }}</p></div>
                    </div>
                    <div class="campo">
                        <div class="label txt-morado"><p>Color:</p></div>
                        <div class="info"><span class="color" :style="item.css + '; border-radius: 50%; border: 2px solid rgb(136, 136, 136); cursor: pointer; width: 30px; display: block; height: 30px;'"></span></div>
                    </div>
                    <div v-if="item.notas" class="campo">
                        <div class="label txt-morado"><p>Notas:</p></div>
                        <div class="info"><p>{{ item.notas }}</p></div>
                    </div>
                    <!-- Botón para abrir modal del iframe -->
                    <button class="button-ver" @click="openDesignModal(order.id_token_order, item.id_order_detail)">
                    Ver diseño
                    </button>              
                    <button v-if="order.id_status == 2" class="button-ver" style="margin-left: 10px;;" @click="openPaymentModal(order.id_token_order)">
                        Proceder al Pago
                    </button>      

                    <button class="button-ver" v-if="item.imagenesterminados && item.imagenesterminados.length > 0" @click="openImageModal(item.imagenesterminados)">Ver imágenes cargadas para terminado</button>
                </div>
                
                <StatusTracker
                    :steps="['Validando pedido', 'Propuesta de diseño', 'Diseño Aprobado', 'Producto en desarrollo', 'En paquetería', 'En camino', 'Entregado']"
                    :currentStep="getStep(order.id_status)"
                    />
            </div>
        </div>    
         <!-- Modal de opciones de pago -->
        <div v-if="showPaymentModal" class="modal-overlay" @click.self="closePaymentModal">
        <div class="modal" style="max-width: 450px;">
            <div class="modal-header">
            <h4>Selecciona una opción de pago</h4>
            </div>
            <div class="modal-body" style="display: flex; flex-direction: column; align-items: center; ">
                <button class="button-ver" @click="payWithStripe">Pagar con Stripe</button>
                <button class="button-ver" @click="payWithCard">Pagar con Tarjeta de Crédito/Débito</button>
                <button class="button-ver" @click="payWithCard">Pagar con Transferencia</button>
            </div>
            <button class="button-cerrar" @click="closePaymentModal">Cerrar</button>
        </div>
        </div>
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
            <div class="modal">
                <div v-for="img in modalImages" :key="img">
                    <img :src="`/img/imagenesterminados/${img}`" alt="Imagen terminada"/>
                </div>
                <button class="button-cerrar" @click="closeModal">Cerrar</button>
            </div>
        </div>    
        <!-- Modal con iframe para visualizar el diseño -->
        <div v-if="showDesignModal" class="modal-overlay" @click.self="closeDesignModal">
            <div class="modal">
                <div class="modal-header">
                <!-- Botón para aprobar el diseño -->
                <button class="button-aprobar" @click="approveDesign">
                    Aprobar diseño
                </button>
                </div>
                <!-- Iframe para mostrar la URL del diseño -->
                <iframe :src="designUrl" class="iframe-design" frameborder="0"></iframe>
                <button class="button-cerrar" @click="closeDesignModal">Cerrar</button>
            </div>
        </div>

    </div>
</template>

  <script>
  import axios from 'axios';
  import StatusTracker from './StatusTracker.vue'; // adjust the import path as needed

  
  export default {
    data() {
      return {
        orders: [],
        showModal: false,
        modalImages: [],
        showDesignModal: false, // Controla si el modal del diseño está visible
        designUrl: '', // URL del iframe
        selectedOrderId: null, // Almacena temporalmente el orderId para la aprobación del diseño
        selectedOrderDetailId: null, // Almacena temporalmente el orderDetailId para la aprobación del diseño
        showPaymentModal: false,
        };
    },
    components: {
        StatusTracker
    },
    created() {
      this.fetchOrders();
    },
    methods: {
        payWithStripe() {
            axios
                .post("/api/pago-stripe", { order_id: this.selectedOrderId })
                .then((response) => {
                if (response.data.url) {
                    window.open(response.data.url, "_blank");
                } else {
                    alert("Error al generar el enlace de pago con Stripe.");
                }
                })
                .catch((error) => {
                console.error("Error al pagar con Stripe:", error);
                });
            },
        openPaymentModal(orderId) {
            this.selectedOrderId = orderId;
            this.showPaymentModal = true;
        },
        closePaymentModal() {
            this.showPaymentModal = false;
        },
        payWithCard() {
            this.showPaymentModal = false;
            this.$router.push(`/pagar-pedido/${this.selectedOrderId}`);
        },
        openDesignModal(orderId, orderDetailId) {
            this.designUrl = `https://backoffice.xpromo.mx/formato/produccion/${orderId}/${orderDetailId}`;
            this.selectedOrderId = orderId; // Almacena el ID de la orden
            this.selectedOrderDetailId = orderDetailId; // Almacena el ID del detalle de la orden
            this.showDesignModal = true;
        },
        closeDesignModal() {
            this.showDesignModal = false;
        },
            // Función para aprobar el diseño, haciendo una petición al backend
        approveDesign() {
            const orderId = this.selectedOrderId; // Usa el valor almacenado en el estado
            const orderDetailId = this.selectedOrderDetailId; // Usa el valor almacenado en el estado
            
            if (!orderId || !orderDetailId) {
                console.error('No se pudieron obtener los IDs de la orden o del detalle.');
                return;
            }
            const token = this.$store.state.user.token;
            // Envía la solicitud para aprobar el diseño
            axios.post('/api/aprobar-diseno', { order_id: orderId, order_detail_id: orderDetailId, token_user: token })
                .then(response => {
                if (response.data.success) {
                    alert('Diseño aprobado con éxito');
                    this.closeDesignModal();
                    // Aquí podrías actualizar el estado de la orden si es necesario
                } else {
                    alert('Error al aprobar el diseño');
                }
                })
                .catch(error => {
                console.error('Error aprobando el diseño:', error);
                alert('Error al aprobar el diseño');
                });
            },
        getStep(id_status) {
            if (id_status == 1) {
            return 1; // "Validando pedido"
            } else if (id_status == 3) {
            return 2; // "Propuesta de diseño"
            } else if (id_status == 4 || id_status == 2) {
            return 3; // "Diseño Aprobado"
            } else if (['5', '6', '7', '8'].includes(id_status)) {
            return 4; // "Producto en desarrollo"
            } else if (['9', '10'].includes(id_status)) {
            return 5; // "En paquetería"
            } else if (id_status == 13) {
            return 6; // "En camino"
            } else if (id_status == 14) {
            return 7; // "Entregado"
            }
            return 1; // Default: Paso inicial "Validando pedido"
        },
        fetchOrders() {
            const token = this.$store.state.user.token;
            axios.post('/api/ordenes', { token })
            .then(response => {
                this.orders = response.data.map(order => ({
                ...order,
                items: order.items.map(item => ({
                    ...item,
                    // Asumiendo que imagenesterminados es un string JSON de un array
                    imagenesterminados: item.imagenesterminados ? JSON.parse(item.imagenesterminados) : []
                }))
                }));
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
            });
        },
      openImageModal(images) {
        this.modalImages = images;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
        this.modalImages = [];
      }
    }
  }
  </script>
  <style lang="sass" scoped>
.order 
    // border-bottom: 1px solid #ccc
    padding: 10px
    margin-bottom: 10px
  
.item 
    border: 1px solid #eee
    padding: 5px
    margin-top: 5px    
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    .imagen
        width: 15%
        text-align: center
        img
            width: 90%

    .data
        width: 80%        
        // flex-direction: column
        // display: flex
        .campo
            font-size: 0.8em
            display: flex
            justify-content: space-between
            margin-top: 5px
            .label
                width: 20%
            .info
                width: 80%
.resumen-compra 
    border: 1px solid #ccc
    border-radius: 8px
    /* padding: 20px */
    margin: 20px 0
    border-radius: 15px
    overflow: hidden
    background: #fff
    width: 100%
    padding: 60px 20px 0 20px
    position: relative
    .vacio
        padding: 30px 15px
    h3 
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: auto
        width: 100%
        font-size: 22px
        font-weight: lighter
        background: #8246AF
        padding: 8px 15px
        color: #fff
.txt-morado
    color: #8246AF            
.txt-bold            
    font-weight: bold
.size-seven
    font-size: 0.7em
.mt-20
    margin-top: 20px
.modal-overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    // margin: auto
    background-color: rgba(0, 0, 0, 0.7)
    display: flex
    justify-content: center
    align-items: center
    z-index: 3
    height: 100vh
    width: 100vw
    .modal 
        background-color: white
        padding: 25px
        width: 95%
        max-width: 1000px
        border-radius: 8px
        text-align: center
        box-shadow: 0 4px 6px rgba(0,0,0,0.1)
        img 
            width: 45%
            max-width: 250px
.button-ver, .button-cerrar 
    background-color: #3C1053
    color: white
    border: none
    padding: 10px 20px
    cursor: pointer
    border-radius: 30px
    width: 100%
    max-width: 290px
    margin-top: 10px
    transition: all 0.2s
    &:hover
        background: #924bb7 

.modal-header 
    display: flex
    justify-content: center
    margin-bottom: 20px

.iframe-design 
    width: 100%
    height: 500px

.button-aprobar 
    background-color: #28a745
    color: white
    border: none
    padding: 10px 20px
    cursor: pointer
    border-radius: 30px
    width: 150px
    transition: all 0.2s
    margin-bottom: 15px

.button-aprobar:hover 
    background-color: #218838

.button-cerrar 
    background-color: #dc3545
    color: white
    border: none
    padding: 10px 20px
    cursor: pointer
    border-radius: 30px
    width: 150px
    transition: all 0.2s
    margin-top: 15px

.button-cerrar:hover 
    background-color: #c82333

  </style>
  