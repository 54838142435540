<template>
    <p class="calificacion">
        <svg width="112" height="20" viewBox="0 0 112 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 15.5279L16.18 19.2579L14.54 12.2279L20 7.49793L12.81 6.88793L10 0.257935L7.19 6.88793L0 7.49793L5.46 12.2279L3.82 19.2579L10 15.5279Z" fill="#F9A318"/>
            <path d="M33 15.5279L39.18 19.2579L37.54 12.2279L43 7.49793L35.81 6.88793L33 0.257935L30.19 6.88793L23 7.49793L28.46 12.2279L26.82 19.2579L33 15.5279Z" fill="#F9A318"/>
            <path d="M56 15.5279L62.18 19.2579L60.54 12.2279L66 7.49793L58.81 6.88793L56 0.257935L53.19 6.88793L46 7.49793L51.46 12.2279L49.82 19.2579L56 15.5279Z" fill="#F9A318"/>
            <path d="M79 15.5279L85.18 19.2579L83.54 12.2279L89 7.49793L81.81 6.88793L79 0.257935L76.19 6.88793L69 7.49793L74.46 12.2279L72.82 19.2579L79 15.5279Z" fill="#F9A318"/>
            <path d="M102 15.5279L108.18 19.2579L106.54 12.2279L112 7.49793L104.81 6.88793L102 0.257935L99.19 6.88793L92 7.49793L97.46 12.2279L95.82 19.2579L102 15.5279Z" fill="#F9A318"/>
        </svg>
    </p>
</template>
<script>
export default {
    
}
</script>
<style lang="sass" scoped>
.calificacion
    margin-bottom: 20px

    
</style>