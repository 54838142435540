<template>
    <div class="resumen-compra">
        <h3>Detalles productos</h3>
        <div v-if="cartItems.length > 0">
            <div v-for="item in cartItems" :key="item.id" class="producto">
                <div class="img">
                    <img :src="item.image" alt="producto"/>

                </div>
                <div class="detalle">
                    <p class="nombre txt-morado txt-bold">{{ item.name }}</p>
                    <p class="descripcion">{{ item.description }}</p>
                    <p class="terminado mt-15"><span class="txt-morado">Color:</span> <span class="color" :style="item.css + '; border-radius: 50%; border: 2px solid #888; cursor: pointer;'"></span> {{ item.color }}</p>
                    <p class="terminado mt-15"><span class="txt-morado">Terminado:</span> {{ item.technique }}</p>
                    <p class="cantidad mt-15"><span class="txt-morado">Cantidad:</span> {{ item.quantity }}</p>
                    <p class="notas mt-15"><span class="txt-morado">Notas:</span> <textarea name="" id="" cols="30" rows="2">{{ item.notas }}</textarea></p>

                    <button class="mt-20 btn-terminado" v-if="item.imagenterminado && item.imagenterminado.length" @click="openModal(item.imagenterminado)">Ver imágenes para terminado</button>

                    <div class="botones">
                        <button class="mt-20 editar" @click="editProduct(item)">Editar</button>
                        <button class="mt-20 borrar" @click="removeFromCart(item.id)">Eliminar</button>
                    </div>
                </div>
                <div class="costo d-none d-md-block">
                    <p class="">MX ${{ (item.price*item.quantity).toFixed(2) }}</p>                
                    <small class="">unidad MX${{ item.price }}</small>
                </div>
            </div>
        </div>
        
        <p v-else class="vacio">Tu carrito está vacío.</p>
        <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
            <div class="modal">
                <div class="modal-content">
                    <img v-for="image in modalImages" :src="'/img/imagenesterminados/'+image" :key="image" class="image-preview"/>
                </div>
                <button @click="closeModal">Cerrar</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            modalImages: []
        };
    },
    computed: {
        cartItems() {
            return this.$store.getters.cartItems;
        }
    },
    
    methods: {
        removeFromCart(productId) {
            this.$store.dispatch('removeFromCart', productId);
        },
        openModal(images) {
            this.modalImages = images;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.modalImages = [];
        },
        editProduct(item) {
            // Asegúrate de pasar `item` completo al llamar a este método
            this.$router.push({ name: 'Producto', params: { id: item.id }, query: { edit: true, cartProductId: item.uniqueId } });
        }

    }
}
</script>


<style lang="sass" scoped>
.color
    width: 20px!important
    height: 20px!important
    @media screen and (min-width: 576px)
        width: 25px!important
        height: 25px!important
    @media screen and (min-width: 768px)
        width: 30px!important
        height: 30px!important
.resumen-compra 
    border: 1px solid #ccc
    border-radius: 8px
    /* padding: 20px */
    margin: 20px 0
    border-radius: 15px
    overflow: hidden
    background: #fff
    .vacio
        padding: 30px 15px
    h3 
        font-size: 22px
        font-weight: lighter
        background: #8246AF
        padding: 8px 15px
        color: #fff
    // div 
        // margin-bottom: 10px
    .producto
        display: flex
        padding: 30px
        border-bottom: 1px solid rgba(155,155,155, 0.5)
        justify-content: space-between
        flex-wrap: wrap
        .img
            width: 100%
            @media screen and (min-width: 576px) 
                width: 20%
            display: flex
            align-items: center
            justify-content: center
            img
                width: 100%
        .detalle
            width: 100%
            @media screen and (min-width: 576px) 
                width: 55%
            .txt-morado
                color: #8246AF            
            .txt-bold            
                font-weight: bold
            .size-seven
                font-size: 0.7em
            .mt-15
                margin-top: 15px
            .mt-20
                margin-top: 20px
            .nombre
            .descripcion
                font-size: 0.5em                
            .cantidad, .terminado, .notas
                display: flex
                font-size: 0.6em
                @media screen and (min-width: 576px)
                    font-size: 0.7em
                @media screen and (min-width: 768px) 
                    font-size: 0.8em
                span
                    margin-right: 10px
                    @media screen and (min-width: 992px)
                        width: 25%
                textarea
                    border: none
                    background: #EDEDED!important
                    border-radius: 5px
                    padding: 10px
            .botones
                text-align: right
                display: flex
                width: 100%
                flex-direction: row-reverse
                .editar
                    margin-left: 20px
                .borrar
                @media screen and (min-width: 576px)
                    flex-direction: row
                    .editar
                        margin-left: 0
                        margin-right: 20px
        .costo
            width: 100%
            display: none
            @media screen and (min-width: 576px) 
                width: 20%
                display: block
            text-align: right
            position: relative
            p 
                font-size: 0.8em
            small
                // position: absolute
                // top: 35px
                // right: 0
                color: gray
                font-size: 0.5em
button 
    color: #8246AF
    border: none
    // color: white
    // padding: 10px 20px
    background: #fff
    font-weight: bold
    text-align: center
    text-decoration: none
    display: inline-block
    font-size: 16px
    
    /* margin: 4px 2px 0 4px */
    cursor: pointer
    // width: 100%
    // border-radius: 30px
    // &:hover 
    //     background-color: #965ec1
  
  
input[type=text] 
    padding: 10px
    margin-top: 5px
    margin-bottom: 20px
    border: 1px solid #ccc
    border-radius: 4px
.btn-terminado
    display: flex
    padding: 7px 20px
    border-radius: 30px
    background: #8246AF
    transition: all 0.2s
    margin-right: 15px
    color: #fff
    font-weight: normal
    &:hover 
        background: #af72dd

.modal-overlay 
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
    z-index: 100


.modal 
    background: white
    padding: 20px
    border-radius: 10px
    width: 80%
    max-width: 500px
    .modal-content
        display: flex
        justify-content: space-around
        padding: 30px
        img
            max-width: 40%
    button
        display: flex
        margin: 20px auto 0 auto


.image-preview 
    max-width: 100%
    height: auto
    margin-top: 10px


  
</style>