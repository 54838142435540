<template>
    <div class="Terminados">
      <h1>Terminados de Productos</h1>
      <div class="card-container">
        <router-link to="/terminados/pad-printing" class="container-card">
            <img src="/img/terminados/tampografia.webp" alt="">
            <div class="card">Tampografía</div>
        </router-link>
        <router-link to="/terminados/screen-printing" class="container-card">
            <img src="/img/terminados/serigrafia.webp" alt="">
            <div class="card">Serigrafía</div>
        </router-link>
        <router-link to="/terminados/sublimation" class="container-card">
            <img src="/img/terminados/sublimacion.webp" alt="">
            <div class="card">Sublimación</div>
        </router-link>
        <router-link to="/terminados/dtf-printing" class="container-card">
            <img src="/img/terminados/dtf.webp" alt="">
            <div class="card">Impresión DTF </div>
        </router-link>
        <router-link to="/terminados/embroidery" class="container-card">
            <img src="/img/terminados/bordado.webp" alt="">
            <div class="card">Bordado</div>
        </router-link>
        <router-link to="/terminados/laser-cutting" class="container-card">
            <img src="/img/terminados/laser.webp" alt="">
            <div class="card">Corte Láser </div>
        </router-link>
        <router-link to="/terminados/laser-engraving" class="container-card">
            <img src="/img/terminados/grabaadolaser.webp" alt="">
            <div class="card">Grabado Láser</div>
        </router-link>
        <router-link to="/terminados/digital-printing" class="container-card">
            <img src="/img/terminados/impresiondigital.webp" alt="">
            <div class="card">Impresión Digital</div>
        </router-link>
        <router-link to="/terminados/uv-printing" class="container-card">
            <img src="/img/terminados/impresionuv.webp" alt="">
            <div class="card">Impresión UV</div>
        </router-link>
        <router-link to="/terminados/containers" class="container-card">
            <img src="/img/terminados/contenedores.webp" alt="">
            <div class="card">Contenedores </div>
        </router-link>
        <router-link to="/terminados/awards" class="container-card">
            <img src="/img/terminados/reconocimientos.webp" alt="">
            <div class="card">Reconocimientos </div>
        </router-link>
        <router-link to="/terminados/developments" class="container-card">
            <img src="/img/terminados/desarrollos.webp" alt="">
            <div class="card">Desarrollos </div>
        </router-link>
      </div>
    </div>
</template>
<script>
  export default {
      
  }
</script>

<style lang="sass" scoped>

.card-container 
    display: flex
    flex-wrap: wrap
    justify-content: space-around


.container-card 
    width: 100%
    min-height: 300px
    // padding: 20px
    background: #fff
    margin: 10px 0
    border-radius: 20px
    overflow: hidden
    text-decoration: none
    color: #333
    margin-x: auto
    img
        width: 100%
    @media screen and (min-width: 350px)
        width: 96%
    @media screen and (min-width: 592px)
        width: 48%
    @media screen and (min-width: 780px)
    @media screen and (min-width: 992px)
        width: 32%
    @media screen and (min-width: 1200px)
        width: 23%
    .card
        padding: 10px 10px 20px 10px
        display: block
        text-align: center
    a
        
</style>
  
