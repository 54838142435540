// src/services/preguntasFrecuentesService.js

import axios from 'axios';

export default {
  async fetchFAQ() {
    try {
      const response = await axios.get('/api/faq');
      return response.data;
    } catch (error) {
      console.error('Error fetching FAQs:', error);
      return [];
    }
  }
}
