<template>
    <div class="carrito-container">
      <div class="carrito-izquierda" >
        <ResumenCarrito v-if="pasoActual === 1" />
        <Direccion v-if="pasoActual === 2" :initialData="addressData" :isLocked="addressLocked" @lock="handleLock" @unlock="handleUnlock" />
        <ConfirmacionPago v-if="pasoActual === 3" :addressData="addressData" :cartItems="cartItems" :totalCost="totalCost"  />
    
        <button v-if="pasoActual === 2 || pasoActual === 3" @click="volverPasoAnterior" class="btn-volver">
          Volver
        </button>
      </div>
  
      <div class="carrito-derecha">
        <ResumenCompra 
              :pasoActual="pasoActual" 
              :totalProductos="totalProductos" 
              :totalCosto="totalCosto"
              :orderSuccess="orderSuccess"
              :addressLocked="addressLocked"
              :orderFolio="orderFolio" @continuar="manejarContinuar" @aplicarCupon="aplicarCupon" @updateTotalCost="handleTotalCostUpdate" />
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import ResumenCarrito from './pasos/ResumenCarrito.vue';
  import Direccion from './Direccion.vue';
  import ConfirmacionPago from './ConfirmacionPago.vue';
  import ResumenCompra from './ResumenCompra.vue';

  
  export default {
    components: {
      ResumenCarrito,
      Direccion,
      ConfirmacionPago,
      ResumenCompra,
    },
    data() {
      return {
        pasoActual: 1,
        total: 0, // Suponiendo que tienes un método para calcular esto basado en los productos del carrito
        cuponDescuento: 0,
        addressLocked: false,
        cartItems: this.$store.getters.cartItems, // Asegúrate de actualizar esta data del carrito
        totalCost: 0,  // Calcula el costo total del carrito
        orderSuccess: false,
        orderFolio: '000000000'
      };
    },
    computed: {
      tituloPasoActual() {
        switch (this.pasoActual) {
          case 1:
            return 'Tu Carrito';
          case 2:
            return 'Método de Pago';
          case 3:
            return 'Confirmar y Pagar';
          default:
            return 'Finalizado';
        }
        // cartItems() {
        //     return this.$store.getters.cartItems;
        // }

      },
      totalProductos() {
          return this.$store.getters.cartItems.reduce((sum, item) => sum + item.quantity, 0);
      },
      totalCosto() {
          return this.$store.getters.cartItems.reduce((sum, item) => sum + (item.quantity * item.price), 0);
      }
    },
    methods: {
      volverPasoAnterior() {
        if (this.pasoActual > 1) {
          this.pasoActual--;
        }
      },
      handleTotalCostUpdate(total) {
        this.totalCost = total; // Actualiza el totalCost basado en el evento emitido desde el componente hijo
      },
      handleLock(data) {
        this.addressData = data;
        this.addressLocked = true;
        // Guardar la dirección en el estado o realizar acciones adicionales
      },
      handleUnlock() {
        this.addressLocked = false;
      },
      manejarContinuar() {
        if (this.$store.getters.isAuthenticated) {
          if (this.pasoActual === 2 && !this.addressLocked) {
            alert('Por favor, guarda los datos de la dirección antes de continuar.');
            return;
          }
          if (this.pasoActual === 3) {
            // Asumiendo que deseas probar el estilo sin realizar la petición POST
            // this.testFinalizarCompra();  // Método de prueba para estilos
            // if (this.isTestMode) {
            // } else {
              this.finalizarCompra();  // Método que realiza la petición POST y maneja la respuesta
            // }
          } else {
            this.pasoActual++;
          }
        } else {
          this.$router.push({ path: '/iniciar-sesion', query: { redirect: 'mi-carrito' } });
        }
      },
      finalizarCompra() {
        const cartItems = this.$store.getters.cartItems;
        const userToken = this.$store.state.user.token;
        const addressDetails = this.addressData;
        const totalCost = this.totalCosto.toFixed(2);  // Usa la propiedad calculada para el costo total

        console.log("Cart Items:", cartItems);
        console.log("User Token:", userToken);
        console.log("Address Details:", addressDetails);
        console.log("Total Cost:", totalCost);

        axios.post('/api/submit-order', {
          cart: cartItems,
          address: addressDetails,
          token: userToken,
          totalCost: totalCost
        })
        .then(response => {
          console.log(response)
          if (response.data.status === 'ok') {
            this.orderFolio = response.data.folio; // Guarda el folio aquí
            this.$store.dispatch('clearCart');
            this.orderSuccess = true;
            this.$emit('updateOrderStatus', true);
          }
        })
        .catch(error => {
          console.error('Error al enviar la orden:', error);
        });
      },
      testFinalizarCompra() {
        // Simula el proceso sin hacer la petición POST
        console.log('Prueba de finalización de compra con estilos:');
        this.orderSuccess = true;
        this.$emit('updateOrderStatus', true);
        // Aquí puedes simular el proceso como si fuera exitoso
      },

      handleAddressSubmission(addressDetails) {
        const cartItems = this.$store.getters.cartItems;
        const userToken = this.$store.state.user.token; // Asumiendo que el token está almacenado aquí

        axios.post('/api/submit-order', {
          cart: cartItems,
          address: addressDetails,
          token: userToken
        })
        .then(response => {
          console.log(response.data);
          console.log('si se logro');
          if (response.status === 'ok') {
            this.$store.dispatch('clearCart');  // Limpia el carrito
            this.orderSuccess = true;  // Mostrar imagen y mensaje
          }
        })
        .catch(error => {
          console.error('Error al enviar la orden:', error);
        });
      }
    },
  };
  </script>
  
  <style lang="sass" scoped>
  /* Aquí van tus estilos */
.carrito-container
    display: flex
    width: 98%
    max-width: 1400px
    margin: 0 auto    
    @media screen and (max-width: 992px)
        flex-direction: column-reverse
        align-items: flex-start

  
.carrito-izquierda
    width: 100%
    @media screen and (min-width: 992px)
        width: 75%
.carrito-derecha 
    width: 25%
    @media screen and (max-width: 992px)
        width: 100%!important
.carrito-izquierda, .carrito-derecha 
    padding: 20px
.btn-volver
  margin-top: 20px
  background-color: #3C1053
  color: #fff
  border: none
  padding: 10px 30px
  cursor: pointer
  border-radius: 15px
  transition: all 0.2s
  &:hover
    background-color: #8246AF
  </style>
  