<template>
    <div class="container">
        <h1>Centro de ayuda</h1>
        <hr>
        <h2>Preguntas frecuentes</h2>
        <div class="preguntas">
            <div 
                v-for="(item, index) in faqs" 
                :key="index" 
                class="pregunta"
                @click="toggle(index)"
            >
                <div class="duda" :class="{ rotated: opened.includes(index) }">
                    <p>{{ item.pregunta }}</p>
                    <span > > </span>
                </div>
                <div v-if="opened.includes(index)" class="respuesta">
                    <p>{{ item.respuesta }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import preguntasFrecuentesService from './preguntasFrecuentesService.js';

export default {
  data() {
    return {
      faqs: [],
      opened: []
    };
  },
  async created() {
    this.faqs = await preguntasFrecuentesService.fetchFAQ();
  },
  methods: {
    toggle(index) {
      const position = this.opened.indexOf(index);
      if (position === -1) {
        this.opened.push(index);
      } else {
        this.opened.splice(position, 1);
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.container
    max-width: 1200px
    width: 95vw
    margin: 0 auto
    h1
        font-size: 1.3em
    hr
        margin: 30px 0
    h2
        font-size: 1em
        margin: 30px 0
    .preguntas
        width: 100%        
        .pregunta
            cursor: pointer
            margin: 20px auto            
            font-size: 0.7em
            .duda
                position: relative
                padding: 10px
                background: #fdfdfd
                border-radius: 10px
                display: flex
                align-items: center
                font-weight: bold
                transition: all 0.2s
                span
                    transform-origin: center
                    transition: transform 0.3s
                    position: absolute
                    right: 15px
                    top: 0
                    bottom: 0
                    line-height: 2
                    margin: auto
                &.rotated
                    border-radius: 10px 10px 0 0
                    span
                        transform: rotate(90deg)
            .respuesta
                padding: 10px
                background: rgba(255,255,255, 0.5)
</style>
