<template>
    <div v-if="loading" class="loading">Cargando...</div>
    <div v-else-if="product" class="section">
        <ImageGallery :images="product.imagenes" :productName="product.product_name" v-model:selectedImage="selectedImage"/>
  
        <div class="detalles">
            <ProductDetails :productName="product.product_name" :description="product.description" :price="product.price"/>            
            <QuantitySelector :minQuantity="product.piezas_caja" :maxQuantity="calculateMaxQuantity()" :currentQuantity="currentQuantity" @update:currentQuantity="currentQuantity = $event" />
            <ColorSelector :colors="product.variantes_color" v-model:selectedColor="selectedColor"/>
              
      
            <p class="txt-morado txt-bold size-seven mt-20">
                Terminado:
            </p>
            <select v-model="selectedTechnique" class="select-technique">
                <option value="Sin terminado">Sin terminado</option>
                <option v-for="tech in product.tecnicaImpresion" :key="tech" :value="tech">
                    {{ tech }}
                </option>
            </select>
            <!-- aqui termina componente  -->
             <!-- aqui inicia componente   -->
       
            <div v-if="selectedTechnique !== 'Sin terminado'" class="contenedor-detalle-terminado">

                <p class="txt-morado txt-bold size-five mt-20">
                    Cargar archivo de imagen que llevará impreso el promocional: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_126_721)">
                            <path d="M10.0835 6.41668H11.9168V8.25001H10.0835V6.41668ZM10.0835 10.0833H11.9168V15.5833H10.0835V10.0833ZM11.0002 1.83334C5.94016 1.83334 1.8335 5.94001 1.8335 11C1.8335 16.06 5.94016 20.1667 11.0002 20.1667C16.0602 20.1667 20.1668 16.06 20.1668 11C20.1668 5.94001 16.0602 1.83334 11.0002 1.83334ZM11.0002 18.3333C6.95766 18.3333 3.66683 15.0425 3.66683 11C3.66683 6.95751 6.95766 3.66668 11.0002 3.66668C15.0427 3.66668 18.3335 6.95751 18.3335 11C18.3335 15.0425 15.0427 18.3333 11.0002 18.3333Z" fill="#007DAB"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_126_721">
                                <rect width="22" height="22" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </p>
                <button class="btn-imagen"
                @click="openImageUploadModal()">
                <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.57143 14.5294H11.4286V7.82353H16L8 0L0 7.82353H4.57143V14.5294ZM0 16.7647H16V19H0V16.7647Z" fill="white"/>
                </svg>
                
                Subir imagen
            </button>
        </div>
        <GarantiaDetails/>
          <!-- Modal para cargar imagen -->
          <div v-if="showImageUploadModal" class="modal-overlay" @click.self="closeModal">
                <div class="modal-imagenes">                
                <div  class="global-drag">
                    <div class="drag-drop-area">
                        <div v-for="(image, index) in uploadedImages" :key="index" class="image-preview">
                            <img :src="getImageSrc(image)" class="image-thumbnail">
                            <button @click="deleteImage(index)" class="delete-image">X</button>
                        </div>
                        <input  v-if="uploadedImages.length < maxImages" type="file" @change="handleFileUpload" ref="fileInput" accept=".ai, .pdf, .svg, .eps, .png" hidden>
                        <div  v-if="uploadedImages.length < maxImages" class="texto">
                            <p v-if="(uploadedImages.length < 1 && uploadedImages.length < maxImages)">Arrastra y suelta aquí tu archivo de imagen.</p>
                            <p>Formatos admitidos: PNG, JPG, AI, PDF, SVG</p>
                            <p>Tamaño máximo: 2MB</p>
                        </div>
                        <button v-if="(uploadedImages.length < 1 && uploadedImages.length < maxImages)"  @click="triggerFileInput" class="button-upload">Buscar imagen</button>
                    </div>
                </div>
                                
                <p @click="triggerFileInput"  class="add-imagen-extra" >                
                    <span v-if="(uploadedImages.length > 0 && uploadedImages.length < maxImages)" >+ Agregar segunda imagen (opcional)</span>
                </p>
                
                <div class="flex-para-notas">
                    <div class="notas-container">
                    <p>Notas</p>
                    <textarea v-model="uploadNotes" placeholder="Déjanos una descripción o detalles de la imagen a colocar en el render. Por ejemplo un texto si quieres que lleve una frase o nombre. (Opcional)"></textarea>
                    </div>
                    <div class="importante-container">
                    <p class="titulo-icono">Importante</p>
                    <p class="texto-importante">
                        Al cargar tu imagen y pagar el producto, pasará a <span>“mis compras”</span> donde podrás ver tu render para aprobarlo o contactarnos para ajustarlo.
                    </p>
                    </div>
                </div>
                <button @click="addImageToCart" class="button-submit">Cargar Archivo</button>
                </div>
            </div>
            <p v-if="selectedColor" class="total-note mt-20 txt-morado size-seven">Total: <b>{{ formattedPrice(total) }}</b></p>

            <!-- <button id="agregarCarrito"
                v-if="showAddToCartButton"
                @click="addToCart()">
                Agregar al carrito
            </button> -->
            <button id="agregarCarrito" @click="isEditing ? updateCart() : addToCart()">
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.96548 15.8709C5.03876 15.8709 4.28896 16.7637 4.28896 17.8548C4.28896 18.9459 5.03876 19.8387 5.96548 19.8387C6.8922 19.8387 7.65043 18.9459 7.65043 17.8548C7.65043 16.7637 6.8922 15.8709 5.96548 15.8709ZM14.3902 15.8709C13.4635 15.8709 12.7137 16.7637 12.7137 17.8548C12.7137 18.9459 13.4635 19.8387 14.3902 19.8387C15.3169 19.8387 16.0752 18.9459 16.0752 17.8548C16.0752 16.7637 15.3169 15.8709 14.3902 15.8709ZM6.1087 12.6471L6.13397 12.5281L6.8922 10.9113H13.1686C13.8005 10.9113 14.3565 10.5046 14.6429 9.88957L17.8949 2.93612L16.429 1.98387H16.4206L15.4938 3.96773L13.1686 8.9274H7.25446L7.14494 8.65957L5.2578 3.96773L4.45745 1.98387L3.66553 0H0.910645V1.98387H2.59559L5.62849 9.51264L4.49115 11.9429C4.35636 12.2206 4.28053 12.548 4.28053 12.8951C4.28053 13.9863 5.03876 14.879 5.96548 14.879H16.0752V12.8951H6.31932C6.2098 12.8951 6.1087 12.786 6.1087 12.6471Z" fill="white"/>
                </svg>
                {{ isEditing ? 'Guardar cambios' : 'Agregar al carrito' }}
            </button>
            <template v-if="!isEditing">
                <button class="btn-similar"
                    @click="viewSimilarProducts()">
                    Ver productos similares
                </button>
                <button class="btn-favoritos"  @click="toggleFavorite">
                    <span class="favorito">
                        <svg v-if="isFavorite" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" viewBox="0 0 24 24"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>
                        
                    </span>
                    Añadir a favoritos

                </button>
            </template>
            <!-- aqui termina componente  -->
             

        </div>
         <!-- aqui termina componente -->
             
        <transition name="fade">
        <div v-if="showModal" class="modal-overlay">
            <div class="modal">
            <transition name="fade">  

                <div class="caja-img">
                <img :src="originalImage" alt="Original Image" class="base-image">
                <transition name="fade">
                    <img v-if="processedImage" :src="processedImage" :key="currentImageKey"
                        :class="{'image-transition': true, 'persist-opacity': imageLoaded}"
                        alt="Processed Image">
                </transition>
                </div>
            </transition>
            <p>{{ modalMessage }}</p>
            </div>
        </div>
        </transition>
        <!-- aqui termina componente -->
    </div>
    
    <div v-else>No se encontró información del producto.</div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
import { mapActions,mapGetters  } from 'vuex';
import { v4 as uuidv4 } from 'uuid';


import CalificacionDetalleProducto from './componentes/fragmento/productDetailsComponentes/CalificacionDetalleProducto.vue';
import DescripcionDetalleProducto from './componentes/fragmento/productDetailsComponentes/DescripcionDetalleProducto.vue';
import ImageGallery from './componentes/ImageGallery.vue';
import ProductDetails from './componentes/fragmento/ProductDetails.vue';
import QuantitySelector from './componentes/fragmento/QuantitySelector.vue';
import ColorSelector from './componentes/fragmento/ColorSelector.vue';
import GarantiaDetails from './componentes/fragmento/GarantiaDetails.vue';

export default {
    name: 'Producto',
    setup() {
        const toast = useToast();
        return { toast };
    },
    components: {
        CalificacionDetalleProducto,
        DescripcionDetalleProducto,
        ImageGallery,
        ProductDetails,
        QuantitySelector,
        ColorSelector,
        GarantiaDetails,
    },
    props: {
        initialProduct: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            product: this.initialProduct || null, 
            loading: true,
            selectedImage: null,
            selectedTechnique: 'Sin terminado',
            cantidadMinima: 1,
            selectedColor: null,
            preOrderEnabled: false,
            showAddToCartButton: true,
            currentQuantity: 1,
            showModal: false,
            modalMessage: '',
            originalImage: '',
            processedImage: null,
            currentImageKey: 0,
            imageLoaded: false,
            messages: ['','Procesando imagen', 'Buscando productos similares', 'Mostrando productos'],
            currentMessageIndex: 0,
            isFavorite: false,
            showImageUploadModal: false,
            uploadedImages: [],
            uploadNotes: '',
            maxImages: 2,  // Máximo número de imágenes
            cart: [],
            isEditing: false
        };
    },
    watch: {

        initialProduct(newVal) { // Observa cambios en la prop para actualizar 'product'
            if (newVal) {
                this.product = newVal;
                this.checkFavoriteStatus();
            }
        },
        selectedTechnique(newVal) {
            if (newVal === 'Sin terminado') {
                // Limpia las imágenes y notas si se selecciona "Sin terminado"
                this.uploadedImages = [];
                this.uploadNotes = '';
            }
        }
    },
    computed: {
        ...mapGetters(['cartItems']),
        total() {
            return (this.currentQuantity * (this.product ? this.product.price : 0)).toFixed(2);
        }
    },
    async mounted() {
        // await this.fetchProductDetails();        
        if (!this.initialProduct) { // Si no se pasa la prop, carga los detalles del producto
            await this.fetchProductDetails();
        } else {
            this.product = this.initialProduct;
        }
        this.updateRecentlyViewed();
        let favorites = JSON.parse(localStorage.getItem('favorites')) || {};
        // console.log(favorites)
        this.isFavorite = favorites.hasOwnProperty(this.product.id_producto_prov);
    },
    methods: {
        ...mapActions(['setImageSearchResults']),
        openImageUploadModal() {
            if (this.uploadedImages.length < this.maxImages) {
                this.showImageUploadModal = true;
            } else {
                alert('Máximo de imágenes alcanzado');
            }
        },
        getImageSrc(image) {
        // Verifica si la imagen ya contiene un path de servidor o es una URL de datos base64
            if (image.startsWith('/img/imagenesterminados/')) {
                // La imagen ya está en el servidor y tiene un path completo
                return image;
            } else if (image.startsWith('data:image')) {
                // La imagen es una vista previa en formato base64
                return image;
            } else {
                // Construye el path para una imagen que ha sido subida pero aún se referencia por nombre de archivo
                return `/img/imagenesterminados/${image}`;
            }
        },
        addImageToCart() {
            if (this.uploadedImages.length === 0) {
            alert("No hay imágenes cargadas.");
            return;
            }

            const formData = new FormData();
            // Añadir cada imagen a formData. Suponiendo que uploadedImages contiene las imágenes en base64 o blobs
            this.uploadedImages.forEach((image, index) => {
            // Convertir base64 a Blob si es necesario y agregarlo a formData
            // El nombre del campo aquí es dinámico: 'imagen1', 'imagen2', etc.
            const blob = this.dataURItoBlob(image); // Método para convertir base64 a Blob
            formData.append(`imagen${index + 1}`, blob, `imagen${index + 1}.png`);
            });

            // Enviar las imágenes al servidor
            axios.post('/api/upload-imagenes-terminados', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            })
            .then(response => {
            // Suponiendo que el servidor devuelve un arreglo con los nombres de las imágenes
            this.uploadedImages = response.data;
            alert("Imágenes cargadas y nombres actualizados por el servidor.");
            })
            .catch(error => {
            console.error("Error al cargar las imágenes:", error);
            alert("Error al cargar las imágenes.");
            });
        },

        // Método para convertir datos base64 a Blob
        dataURItoBlob(dataURI) {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString});
        },
        closeModal() {
            this.showImageUploadModal = false;
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = (e) => {
                this.uploadedImages.push(e.target.result);
                // this.showImageUploadModal = false; // Cierra el modal después de cargar
            };
            reader.readAsDataURL(file);
        },
        deleteImage(index) {
            this.uploadedImages.splice(index, 1);
        },
        addSecondImage() {
      // Abrir modal de nuevo para cargar otra imagen
            this.openImageUploadModal();
        },
        formattedPrice(value) {
            return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        },
        toggleFavorite(event) {
            event.stopPropagation(); // Previene la propagación para evitar interacciones no deseadas
            this.isFavorite = !this.isFavorite;
            this.updateLocalStorage();
        },
        checkFavoriteStatus() {
            let favorites = JSON.parse(localStorage.getItem('favorites')) || {};
            this.isFavorite = favorites.hasOwnProperty(this.product.id_producto_prov);
        },
        updateLocalStorage() {
            let favorites = JSON.parse(localStorage.getItem('favorites')) || {};
            if (this.isFavorite) {
                favorites[this.product.id_producto_prov] = this.product;
            } else {
                delete favorites[this.product.id_producto_prov];
            }
            localStorage.setItem('favorites', JSON.stringify(favorites));
        },
        calculateMaxQuantity() {
            if (this.selectedColor) {
                const maxQuantity = this.preOrderEnabled || Number(this.selectedColor.inventario) < Number(this.product.piezas_caja)
                    ? Infinity
                    : Number(this.selectedColor.inventario);
                return maxQuantity; // Retorna como número
            }
            return Infinity; // Default a Infinity como número
        },
        triggerFileInput() {
        // alert('Triggered file input'); // Alert para depurar
          this.$refs.fileInput.click();
        },          
        handleText(index) {
            this.clearCurrentInterval(); // Limpia el intervalo actual antes de comenzar otro
            this.eraseMessage(() => {
            this.typeNextMessage(index);
            });
        },
        clearCurrentInterval() {
            if (this.currentInterval) {
            clearInterval(this.currentInterval);
            this.currentInterval = null;
            }
        },
        eraseMessage(callback) {
            let i = this.modalMessage.length;
            this.currentInterval = setInterval(() => {
            if (i > 0) {
                this.modalMessage = this.modalMessage.substring(0, i - 1);
                i--;
            } else {
                this.clearCurrentInterval();
                if (callback) callback(); // Ejecuta el callback después de borrar
            }
            }, 15);
        },
        typeNextMessage(index) {
            const messages = ['','Procesando imagen', 'Buscando productos similares', 'Mostrando productos'];
            let i = 0;
            this.currentInterval = setInterval(() => {
            if (i < messages[index].length) {
                this.modalMessage += messages[index].charAt(i);
                i++;
            } else {
                this.clearCurrentInterval();
            }
            }, 30);
        },

        // selectColor(variant) {
        //     this.selectedColor = variant;
        //     this.updateQuantityLimits();
        // },   
        updateQuantityLimits() {
          
            const cantidadInput = document.getElementById('cantidad');
            if (cantidadInput) {
                cantidadInput.min = this.product.piezas_caja;
                cantidadInput.value = Math.max(this.product.piezas_caja, cantidadInput.value);

                // Ajustar el máximo permitido según el inventario o permitir cualquier cantidad si el preOrder está habilitado
                cantidadInput.max = this.preOrderEnabled ? Infinity : Math.max(this.selectedColor.inventario, this.product.piezas_caja);

                // Mostrar el botón de agregar al carrito si hay preOrder habilitado o si hay stock suficiente
                this.showAddToCartButton = true;
            } else {
                // Puedes manejar el caso en que el elemento no exista, o intentar asegurarte de que se llame más tarde
                console.error("Elemento 'cantidad' no encontrado en el DOM");
            }
        },

        validateQuantity() {
        // Verificar si this.product y this.product.piezas_caja están definidos
        if (this.product && typeof this.product.piezas_caja === 'number') {
            if (this.currentQuantity < this.product.piezas_caja) {
                this.currentQuantity = this.product.piezas_caja;
            }
        } else {
            console.warn('Propiedad product.piezas_caja no está definida o no es un número');
        }

        // Verificar si this.selectedColor e inventario están definidos y son números
        if (this.selectedColor && typeof this.selectedColor.inventario === 'number') {
            if (this.currentQuantity > this.selectedColor.inventario) {
                this.currentQuantity = this.selectedColor.inventario;
            }
        } else if (this.selectedColor) {
            console.warn('Propiedad selectedColor.inventario no está definida o no es un número');
        }
    },

        async fetchProductDetails() {
            // const isEditing = this.$route.query.edit;
            const isEditing = this.$route.query.edit === 'true';
            this.isEditing = isEditing;
            const cartProductId = this.$route.query.cartProductId;
            console.log(isEditing)
            
            const id = this.$route.params.id;
            
            try {
                // Realiza la petición al servidor para obtener los detalles del producto
                const response = await axios.get(`/api/detalleproducto/${id}`);
                this.product = response.data;

                if (this.product && typeof this.product.piezas_caja === 'string') {
                    this.product.piezas_caja = Number(this.product.piezas_caja);
                }

                if (this.product && typeof this.product.price === 'string') {
                    this.product.price = Number(this.product.price);
                }

                this.product.tecnicaImpresion = JSON.parse(
                    this.product.tecnicaImpresion.replace(/\\u00/g, '%').replace(/\\/g, '').replace(/%/g, '\\u00')
                );
                this.selectedImage = this.product.imagenes[0];
                this.cantidadMinima = this.product.piezas_caja || 1;
                this.loading = false;
                this.currentQuantity = Math.max(this.product.piezas_caja, this.currentQuantity);

                // console.log(this.product.variantes_color)
                if (this.product.variantes_color && this.product.variantes_color.length > 0) {
                    this.$nextTick(() => {
                        this.selectColor(this.product.variantes_color[0]);
                    });
                }
                
                this.updateRecentlyViewed();
                this.validateQuantity();
                // console.log('por validar editar');

                // Si es una edición, sobrescribe los campos con los valores del carrito
                if (isEditing && cartProductId) {
                    // console.log('editando producto');
                    
                    // console.log(cartProducts);
                    // console.log(cartProductId);

                    const productInCart = this.cartItems.find(p => p.uniqueId === cartProductId);
            
                    // const productInCart = cartProducts.find(p => p.uniqueId === cartProductId);
                    // console.log(productInCart);
                    if (productInCart) {
                        // console.log('si paso validacion');
                        // console.log('Producto encontrado en el carrito:', productInCart.color);

                        // Asignar el objeto `selectedColor` basado en el nombre del color
                        const colorToSelect = this.product.variantes_color.find(color => color.color === productInCart.color);
                        if (colorToSelect) {
                            this.selectedColor = colorToSelect;

                            // Espera a que los elementos del DOM estén completamente renderizados
                            this.$nextTick(() => {
                                // Encuentra todos los elementos DOM que representan los colores
                                const colorElements = document.querySelectorAll('.color-circle');

                                colorElements.forEach((element, index) => {
                                    // Busca el índice del color coincidente en la lista de variantes de color
                                    if (this.product.variantes_color[index].color === colorToSelect.color) {
                                        // console.log('Simulando clic en el color:', colorToSelect.color);
                                        element.click(); // Simula un clic en el elemento encontrado
                                    }
                                });
                            });
                        }

                        // Sobrescribe los valores específicos
                        this.product.id = productInCart.id;  // Asegúrate de que el ID sea el mismo
                        this.selectedImage = productInCart.image || this.selectedImage;
                        this.selectedTechnique = productInCart.technique || this.selectedTechnique;
                        this.currentQuantity = productInCart.quantity || this.currentQuantity;
                        // this.selectedColor = productInCart.color || this.selectedColor;
                        this.uploadedImages = productInCart.imagenterminado || [];
                        this.uploadNotes = productInCart.notas || '';
                        this.showAddToCartButton = false;  // Mostrar el botón de "Guardar cambios"
                    }
                }
            } catch (error) {
                console.error('Error fetching product details:', error);
                this.loading = false;
            }
        },
        updateRecentlyViewed() {
            // console.log("Updating recently viewed products...");

            if (this.product) {
                let products = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
                // console.log("Current products in storage before update:", products);

                // Agregar el nuevo producto al inicio
                products.unshift(this.product);

                // Crear un nuevo array para manejar duplicados
                let uniqueProducts = [];
                let foundDuplicate = false;

                // Iteramos sobre la lista para asegurarnos de eliminar solo duplicados adicionales
                for (let i = 0; i < products.length; i++) {
                    if (products[i].id_product === this.product.id_product) {
                        if (!foundDuplicate) {
                            // Agregamos el producto la primera vez que lo encontramos
                            uniqueProducts.push(products[i]);
                            foundDuplicate = true;
                        }
                        // Si encontramos un duplicado después de agregarlo la primera vez, lo ignoramos
                    } else {
                        uniqueProducts.push(products[i]);
                    }
                }

                // Limitar el número de entradas a 20
                if (uniqueProducts.length > 20) {
                    uniqueProducts = uniqueProducts.slice(0, 20);  // Mantener solo los primeros 20 elementos
                }

                // Guardar el array actualizado de vuelta en localStorage
                localStorage.setItem('recentlyViewed', JSON.stringify(uniqueProducts));

                // Si el usuario está conectado, también actualizar en el servidor
                if (this.isLoggedIn) {
                    axios.post('/api/updateRecentlyViewed', { products: uniqueProducts })
                        .then(response => {
                            // console.log('Successfully updated server with recently viewed products.');
                        })
                        .catch(error => {
                            console.error('Failed to update recently viewed products on server.', error);
                        });
                }
            } else {
                // console.log("No product to update.");
            }
        },
        selectColor(variant) {
            if (typeof variant === 'string') {
                // Buscar el objeto completo en la lista de variantes de color por su nombre
                this.selectedColor = this.product.variantes_color.find(color => color.nombre === variant) || null;
            } else {
                this.selectedColor = variant;
            }
            this.updateQuantityLimits();
        },
        addToCart() {
            const uniqueId = uuidv4();
            const productToAdd = {
                uniqueId,
                id: this.product.id_producto_prov,
                description: this.product.description,
                name: this.product.product_name,
                price: this.product.price,
                quantity: parseInt(document.getElementById('cantidad').value),
                image: this.selectedImage,
                technique: this.selectedTechnique,
                color: this.selectedColor ? this.selectedColor.color : '',
                css: this.selectedColor ? this.selectedColor.css : '',
                imagenterminado: this.uploadedImages,
                notas: this.uploadNotes
            };
            // console.log(productToAdd);
            this.$store.dispatch('addToCart', productToAdd);
            this.notifyAddToCart();
        },
        updateCart() {
            console.log("Actualizando el producto en el carrito");
            const cartProductId = this.$route.query.cartProductId;
            console.log( this.selectedColor.color)
            if (cartProductId) {
                // Lógica para actualizar el producto en el carrito basado en cartProductId
                const productIndex = this.cartItems.findIndex(item => item.uniqueId === cartProductId);
                if (productIndex !== -1) {
                    this.cartItems[productIndex] = {
                        ...this.cartItems[productIndex],
                        quantity: this.currentQuantity,
                        image: this.selectedImage,
                        technique: this.selectedTechnique,
                        color: this.selectedColor.color,
                        css: this.selectedColor.css,
                        imagenterminado: this.uploadedImages,
                        notas: this.uploadNotes
                    };
                    // console.log(this.cartItems[productIndex])
                    this.$store.commit('updateCart', this.cartItems[productIndex]);
                    this.toast.success("Producto actualizado en el carrito");
                } else {
                    this.toast.error("Producto no encontrado en el carrito");
                }
            }
        },
        notifyAddToCart() {
            if (this.preOrderEnabled) {
                
                if (this.selectedColor.inventario < this.product.piezas_caja) {
                    this.toast.warning("Este producto se agregó correctamente, y su solicitud será revisada por un ejecutivo para validar su pedido");
                } else {
                    this.toast.success("Productos agregados correctamente");          
                }
            } else {
                this.toast.success("Productos agregados correctamente");          
            }
        },
        async viewSimilarProducts() {
            const imageElement = document.querySelector('.actual img');
            // const file = event.target.files[0];  
            if (!imageElement) {
                throw new Error('No image selected');
            }

            this.showModal = true;
            // console.log(imageElement.src);
            this.originalImage = imageElement.src; // Usa la URL de la imagen
            const formData = new FormData();
            formData.append('file', imageElement.src);
            // Inicia la animación de texto con el primer mensaje
            this.handleText(1);
            // Primero, limpia la imagen
            setTimeout(() => {
                this.handleText(2);            
            }, 3000);
            // Primero, limpia la imagen si es necesario
            const cleanResponse = await fetch('/api/clean_image', {
                method: 'POST',
                body: formData,
            });
             
            setTimeout(() => {
                this.handleText(3);
            }, 1500);

            if (!cleanResponse.ok) {
                const errorText = await cleanResponse.text();
                throw new Error(`Failed to clean the image: ${errorText}`);
            }

            const cleanBlob = await cleanResponse.blob();
            const cleanFormData = new FormData();
            this.processedImage = URL.createObjectURL(cleanBlob);  // Guarda la imagen procesada
            this.currentImageKey++;  // Incrementa para forzar la transición         
            setTimeout(() => {
                // this.showModal = false;  // Oculta el modal
                this.imageLoaded = true; 
            }, 300);

            cleanFormData.append('file', cleanBlob, 'cleaned-image.jpg'); // Usa un nombre genérico o el nombre original

            // Luego, sube la imagen limpia
            const uploadResponse = await fetch('/api/upload_image', {
                method: 'POST',
                body: cleanFormData,
            });

            if (!uploadResponse.ok) {
                const errorText = await uploadResponse.text();
                throw new Error(`Failed to upload the cleaned image: ${errorText}`);
            }

            const similarImages = await uploadResponse.json();
            setTimeout(() => {
                this.showModal = false;  // Oculta el modal
                setTimeout(() => {
                this.originalImage = '';  // Oculta el modal
                this.processedImage = null;  // Oculta el modal
                this.imageLoaded = false;  // Oculta el modal
                }, 100);
                this.handleText(0);
            }, 100);
            this.setImageSearchResults(similarImages);
            this.$router.push({ name: 'SearchResults', query: { searchTerm: 'busqueda-inteligente' } });
            try {

            } catch (error) {
                console.error('Error processing the image:', error);
                this.toast.error("Hubo un error al buscar productos similares. Inténtalo de nuevo.");
            }
        }
    }
}
</script>


<style lang="sass" scoped>


.modal-imagenes
    background-color: white
    padding: 25px
    width: 95%
    max-width: 1000px
    border-radius: 8px
    text-align: center
    box-shadow: 0 4px 6px rgba(0,0,0,0.1)
    .image-preview 
        width: 150px
        height: 150px
        object-fit: cover
        margin: 5px
        position: relative
        margin: 30px auto
        .image-thumbnail 
            width: 150px 
            height: 150px
            object-fit: contain

    .delete-image 
        position: absolute
        top: 0
        right: 0
        background-color: red
        color: white
        border: none
        cursor: pointer
        width: 40px
        display: flex
        justify-content: center
        align-items: center
    
       
    .button-submit 
        background-color: #3C1053
        color: white
        border: none
        padding: 10px 20px
        cursor: pointer
        border-radius: 30px
        width: 100%
        max-width: 290px
        margin-top: 40px
        transition: all 0.2s
        &:hover
          background: #924bb7  
    .global-drag
        background: #EDEDED
        border-radius: 5px
        padding: 30px 10px
        .drag-drop-area 
            border: 3px dashed #ccc
            padding: 30px
            margin: 10px auto
            width: 80%
            max-width: 500px
            border-radius: 20px
            color: #696969
            p 
                font-size: 0.6em
                margin: 10px auto
            .button-upload
                background: #848484
                display: flex
                align-items: center
                justify-content: center
                border-radius: 30px
                margin: 10px auto                
                color: white
                border: none
                padding: 10px 20px
                cursor: pointer
                max-width: 300px
                svg 
                    transform: scale(0.8)
                    margin-right: 10px
    .add-imagen-extra
        font-size: 0.7em
        // text-decoration: underline
        margin: 15px auto 30px auto
        color: #2F80ED
        cursor: pointer
        display: flex 
        padding: 0 2px 3px 0
        border-bottom: 1px solid #2F80ED
        width: fit-content
    .flex-para-notas
        display: flex
        justify-content: space-between
        align-items: flex-start
        flex-wrap: wrap
        .notas-container
            width: 100%
            @media screen and (min-width: 992px)
                width: 48%
            p 
                text-align: left
                font-size: 0.6em
                color: #8246AF
            textarea 
                width: 100%
                height: 100px
                margin-top: 10px
                padding: 10px
                // border: 1px solid #ccc
                border: none 
                background: #EDEDED
                border-radius: 5px
        .importante-container
            width: 100%
            @media screen and (min-width: 992px)
                width: 48%
            .titulo-icono
                display: flex
                align-items: center
                font-size: 0.6em
                color: #8246AF
                svg
                    transform: scale(0.8)
            .texto-importante
                font-size: 0.6em
                color: #030303
                text-align: justify
                margin-top: 10px
                span
                    font-weight: bold
        
    h3 
        color: #3C1053
        margin-bottom: 15px

    // ------

.out-of-stock
    font-size: 0.4em
    text-align: center
    margin-top: 30px
.color-circle
    width: 30px
    height: 30px
    display: inline-block
    margin-right: 10px

.select-technique
    padding: 10px
    margin-top: 0
    padding: 10px
    margin-top: 5px
    margin-bottom: 0px
    border: 1px solid #ccc
    border-radius: 4px
    width: 100%
    max-width: 230px
    border: none
    background: #EDEDED

.loading
    text-align: center
    font-size: 20px

.section
    display: flex
    justify-content: space-between
    flex-direction: row
    @media (max-width: 768px)
        flex-direction: column


.detalles
    background: #fff
    border-radius: 15px
    box-shadow: 0 0 15px rgba(155, 155, 155, 0.5)
    width: 25%
    padding: 15px
    @media (max-width: 768px)
        width: 100%
        margin-top: 20px

    .txt-morado
        color: #8246AF

    .txt-bold
        font-weight: bold

    .size-seven
        font-size: 0.7em
    .size-five
        font-size: 0.5em
        line-height: 0.7
        svg 
            margin-top: -5px
            transform: scale(1.4)
            padding-top: 10px

    .size-four
        font-size: 0.5em

    
    .mt-10
        margin-top: 10px
    .mt-15
        margin-top: 15px
    .mt-20
        margin-top: 20px
    .nombre
        color: #8246AF
        font-weight: bold
        font-size: 1em

   

    #agregarCarrito
        background: #3C1053
        border: none
        border-radius: 30px
        transition: all 0.2s
        display: flex
        align-items: center
        justify-content: center
        margin-top: 30px
        svg 
            margin-right: 10px
        &:hover
            background: #8246AF

button 
    background-color: #8246AF
    border: none
    color: white
    padding: 10px 20px
    text-align: center
    text-decoration: none
    display: inline-block
    font-size: 16px
    cursor: pointer
    width: 100%
    border-radius: 30px
    transition: all 0.2s

button:hover 
    background-color: #965ec1

input[type=text], input[type=number]
    padding: 10px
    margin-top: 5px
    margin-bottom: 20px
    border: 1px solid #ccc
    border-radius: 4px
    width: 100%


.modal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  // margin: auto
  background-color: rgba(0, 0, 0, 0.7)
  display: flex
  justify-content: center
  align-items: center
  z-index: 3
  height: 100vh
  width: 100vw

.modal
  background-color: white
  padding: 20px
  border-radius: 10px
  text-align: center
  max-width: 400px  
  width: 90%
  .caja-img
    position: relative
    max-width: 360px
    width: 100%
    height: 360px
    overflow: hidden
    .base-image
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      right: 0
      margin: auto
      object-fit: contain
      border-radius: 15px
.image-transition
  position: absolute
  top: 0
  left: 0
  max-width: 360px
  width: 100%
  height: 100%
  object-fit: cover
  border-radius: 15px
  transition: opacity 0.5s ease-in-out
  opacity: 0  // Inicia invisible
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s
.fade-enter, .fade-leave-to
  opacity: 0
.fade-enter-to
  opacity: 1
.persist-opacity 
  opacity: 1 !important  // Uso de !important para asegurar que la opacidad se mantenga

p
//   color: #3C1053
//   font-weight: bold
//   min-height: 34px
//   margin-top: 10px
.tiempo 
    font-size: 0.6em!important
    display: flex
    align-items: center
    svg
        transform: scale(0.7)
        cursor: pointer
.btn-similar
    margin: 5px 0
.btn-favoritos
    background: #B089CD
    display: flex
    justify-content: center
    align-items: center
    padding: 5px 20px 5px 20px
    &:hover
        background: #8f2323
    svg 
        transform: scale(0.9)
        margin-right: 5px
.btn-imagen
    background: #B9AFBB
    display: flex
    align-items: center
    justify-content: center
    margin: 8px auto
    svg 
        transform: scale(0.8)
        margin-right: 10px
    // max-width: 100%
    // svg
    //     fill: #fff
</style>
