<template>
    <div class="resumen-compra">
      <h3>Confirmacion pedido</h3>
      <div class="detalles">
      <div class="adicionales">
        <div class="icono">
            <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5 0C5.14214 0 0 4.5385 0 10.15C0 17.7625 11.5 29 11.5 29C11.5 29 23 17.7625 23 10.15C23 4.5385 17.8579 0 11.5 0ZM11.5 13.775C9.23286 13.775 7.39286 12.151 7.39286 10.15C7.39286 8.149 9.23286 6.525 11.5 6.525C13.7671 6.525 15.6071 8.149 15.6071 10.15C15.6071 12.151 13.7671 13.775 11.5 13.775Z" fill="#582C83"/>
</svg>

        </div>
        <div class="informacion">
          <h4>Datos de Envío</h4>
          <p>Dirección: {{ addressData.direccion }}, No. Ext: {{ addressData.numExterior }}{{ addressData.numInterior ? ', No. Int: ' + addressData.numInterior : '' }}</p>
          <p>Código Postal: {{ addressData.postal_code }}</p>
          <p>Estado: {{ addressData.estado }}</p>
          <p>Municipio: {{ addressData.municipio }}</p>
          <p>Colonia: {{ addressData.colonia }}</p>
        </div>
      </div>
      <div class="adicionales">
        <div class="icono">
            <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.9091 5.5H21.8182V0H2.72727C1.22727 0 0 1.2375 0 2.75V17.875H2.72727C2.72727 20.1575 4.55455 22 6.81818 22C9.08182 22 10.9091 20.1575 10.9091 17.875H19.0909C19.0909 20.1575 20.9182 22 23.1818 22C25.4455 22 27.2727 20.1575 27.2727 17.875H30V11L25.9091 5.5ZM6.81818 19.9375C5.68636 19.9375 4.77273 19.0163 4.77273 17.875C4.77273 16.7337 5.68636 15.8125 6.81818 15.8125C7.95 15.8125 8.86364 16.7337 8.86364 17.875C8.86364 19.0163 7.95 19.9375 6.81818 19.9375ZM25.2273 7.5625L27.9 11H21.8182V7.5625H25.2273ZM23.1818 19.9375C22.05 19.9375 21.1364 19.0163 21.1364 17.875C21.1364 16.7337 22.05 15.8125 23.1818 15.8125C24.3136 15.8125 25.2273 16.7337 25.2273 17.875C25.2273 19.0163 24.3136 19.9375 23.1818 19.9375Z" fill="#582C83"/>
            </svg>

        </div>
        <div class="informacion">
            <h4>Detalles del Pedido</h4>
            <ul>
                <li v-for="(item, index) in cartItems" :key="index">
                Producto: {{ item.name }}, Cantidad: {{ item.quantity }}
                </li>
            </ul>
            
          </div>
      </div>
    </div>
      
    </div>
  </template>
  
  <script>
  export default {
    props: {
      addressData: {
        type: Object,
        required: true
      },
      cartItems: {
        type: Array,
        required: true
      },
      totalCost: {
        type: Number,
        required: true
      }
    },
    methods: {
  
    }
  }
  </script>
  
<style scoped lang="sass" >
.resumen-compra 
    border: 1px solid #ccc
    border-radius: 8px
    /* padding: 20px */
    margin: 20px 0
    border-radius: 15px
    overflow: hidden
    background: #fff
    .vacio
        padding: 30px 15px
    h3 
        font-size: 22px
        font-weight: lighter
        background: #8246AF
        padding: 8px 15px
        color: #fff
    .detalles
        padding: 30px  
        .adicionales
            padding: 30px 0
            border-bottom: 1px solid rgba(155,155,155, 0.6)
            display: flex
            justify-content: space-between
            .icono
                width: 10%
                display: flex
                justify-content: center
            .informacion
                width: 90%
                h4
                    color: #8246AF
                    font-weight: bold
                p
                    font-size: 0.8em
                    color: #8246AF
                ul 
                    list-style: none
                    li
                        color: #8246AF
                        font-size: 0.6em
  </style>
  