<template> 
<div class="favoritos" @click="navigate">
    <span class="icono">
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6016 0.977295C14.6984 0.977295 12.8719 1.78719 11.6797 3.06702C10.4875 1.78719 8.66094 0.977295 6.75781 0.977295C3.38906 0.977295 0.742188 3.39698 0.742188 6.47657C0.742188 10.2561 4.46094 13.3357 10.0938 18.0151L11.6797 19.3249L13.2656 18.0051C18.8984 13.3357 22.6172 10.2561 22.6172 6.47657C22.6172 3.39698 19.9703 0.977295 16.6016 0.977295ZM11.7891 16.5252L11.6797 16.6252L11.5703 16.5252C6.36406 12.2158 2.92969 9.36619 2.92969 6.47657C2.92969 4.47683 4.57031 2.97703 6.75781 2.97703C8.44219 2.97703 10.0828 3.9669 10.6625 5.33672H12.7078C13.2766 3.9669 14.9172 2.97703 16.6016 2.97703C18.7891 2.97703 20.4297 4.47683 20.4297 6.47657C20.4297 9.36619 16.9953 12.2158 11.7891 16.5252Z" fill="white"/>
            </svg>
            
    </span>
    <span class="txt">
        Favoritos
    </span>
</div>
</template>

<script>
export default {
    name: 'FavoritosLink',
    methods: {
          navigate() {
              // Aquí deberías incluir tu lógica para verificar si la sesión está activa
              // Esto podría implicar verificar un token en localStorage, Vuex, etc.
              // A continuación, se muestra un ejemplo genérico.
              const sessionActive = this.checkSession(); // Implementa esta función según tu lógica de autenticación
  
            //   if (sessionActive) {
                  this.$router.push('/mis-favoritos');
            //   } else {
            //       this.$router.push('/iniciar-sesion');
            //   }
          },
          checkSession() {
              // Implementa tu lógica de verificación de sesión aquí
              // Por ejemplo: return !!localStorage.getItem('userToken');
              return false; // Este es solo un valor de ejemplo
          }
      }
}
</script>

<style lang="sass">
.favoritos
    .txt
        display: none
        @media screen and (min-width: 1200px)
            display: inline-block
</style>