<template>
  <div class="accesos-rapidos">
    <MisCompras></MisCompras>
    <FavoritosLink></FavoritosLink>
    <Notificaciones></Notificaciones>
    <Carrito></Carrito>
</div>
</template>

<script>
import Carrito from './accesosRapidos/Carrito.vue'
import FavoritosLink from './accesosRapidos/FavoritosLink.vue'
import MisCompras from './accesosRapidos/MisCompras.vue'
import Notificaciones from './accesosRapidos/Notificaciones.vue'

export default {
    name: 'AccesosRapidos',
    components: {
        MisCompras,
        FavoritosLink,
        Notificaciones,
        Carrito,
    }
}
</script>

<style lang="sass">
.accesos-rapidos
    display: flex
    align-items: center
    justify-content: space-around
    @media screen and (max-width: 1200px)
        width: 30%
    @media screen and (max-width: 1100px)
        width: 25%
    @media screen and (max-width: 1000px)
        width: 20%
    @media screen and (max-width: 780px)
        width: 100%
    @media screen and (min-width: 1200px)
        justify-content: space-between
    div
        display: flex
        align-items: center                    
        margin: 0 5px
        @media screen and (min-width: 1400px)
            margin: 0 13px
        cursor: pointer
        .icono
            margin-right: 5px
        span
            line-height: 0
    
    
    
    
</style>