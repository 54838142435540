<template>
  <div>
    <div v-if="direcciones.length > 0">
      <h3>Selecciona una dirección existente</h3>
      <div class="tarjeta-direccion">
        <div v-for="(direccion, index) in direcciones" :key="index" class="int-tarjeta-direccion">
          <p><strong>Dirección:</strong> {{ direccion.direccion }}, {{ direccion.numExterior }} {{ direccion.numInterior }}</p>
          <p><strong>Código Postal:</strong> {{ direccion.postal_code }}</p>
          <p><strong>Estado:</strong> {{ direccion.estado }}</p>
          <p><strong>Municipio:</strong> {{ direccion.municipio }}</p>
          <p><strong>Colonia:</strong> {{ direccion.colonia }}</p>
          <button @click="seleccionarDireccion(direccion)">Seleccionar esta dirección</button>
        </div>
      </div>
      <button @click="mostrarFormulario = true" class="btn-nueva-direccion">Agregar nueva dirección</button>
    </div>

    <div v-else>
      <h3>No se encontraron direcciones, por favor agrega una nueva:</h3>
    </div>

    <!-- Formulario para agregar nueva dirección -->
    <div v-if="!direcciones.length || mostrarFormulario">
      <div class="resumen-compra">
        <h3>Datos de Envío</h3>
        <form @submit.prevent="handleFormSubmit">
          <div>
            <label for="direccion">Dirección:</label>
            <input id="direccion" v-model="direccion" required>
          </div>
          <div>
            <label for="numExterior">Número Exterior:</label>
            <input id="numExterior" v-model="numExterior" required>
          </div>
          <div>
            <label for="numInterior">Número Interior:</label>
            <input id="numInterior" v-model="numInterior">
          </div>
          <div>
            <label for="postal_code">Código Postal:</label>
            <input id="postal_code" v-model="postal_code" @input="handlepostal_codeInput" required>
          </div>
          <div>
            <label for="estado">Estado:</label>
            <select id="estado" v-model="estado" :disabled="true" required>
              <option v-for="(est, index) in estados" :key="index" :value="est">{{ est }}</option>
            </select>
          </div>
          <div>
            <label for="municipio">Municipio:</label>
            <select id="municipio" v-model="municipio" :disabled="municipios.length === 0" required>
              <option v-for="(mun, index) in municipios" :key="index" :value="mun">{{ mun }}</option>
            </select>
          </div>
          <div>
            <label for="colonia">Colonia:</label>
            <select id="colonia" v-model="colonia" :disabled="colonias.length === 0" required>
              <option v-for="(col, index) in colonias" :key="index" :value="col">{{ col }}</option>
            </select>
          </div>
          <button type="submit">{{ isLocked ? 'Editar' : 'Guardar' }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    userId: {
      type: String,
      required: true
    },
    initialData: {
      type: Object,
      default: () => ({})
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      direcciones: [],
      mostrarFormulario: false,
      direccion: '',
      numExterior: '',
      numInterior: '',
      postal_code: '',
      estado: '',
      municipio: '',
      colonia: '',
      estados: [],
      municipios: [],
      colonias: []
    };
  },
  methods: {
    async cargarDirecciones() {
      try {
        const userToken = this.$store.state.user.token; // Asumiendo que el token está almacenado aquí
        const response = await axios.get(`/api/addresses/user/${userToken}`);
        this.direcciones = response.data;
        if (this.direcciones.length === 0) {
          this.mostrarFormulario = true;
        }
      } catch (error) {
        console.error('Error al obtener direcciones:', error);
      }
    },
    async handlepostal_codeInput() {
      if (this.postal_code.length === 5) {
        try {
          const response = await axios.get(`/api/codigo-postal/${this.postal_code}`);
          const data = response.data;

          if (data) {
            this.estados = [data.estado];
            this.estado = data.estado;
            this.municipios = [data.municipio];
            this.municipio = data.municipio;
            this.colonias = data.colonias;
            this.colonia = this.colonias.length > 0 ? this.colonias[0] : '';
          } else {
            alert('Código postal no encontrado.');
            this.resetFormFields();
          }
        } catch (error) {
          console.error('Error al obtener información del código postal:', error);
          alert('No se pudo recuperar la información. Inténtalo más tarde.');
          this.resetFormFields();
        }
      }
    },
    handleFormSubmit() {
      if (this.isLocked) {
        this.$emit('unlock');
      } else {
        this.$emit('lock', {
          direccion: this.direccion,
          numExterior: this.numExterior,
          numInterior: this.numInterior,
          postal_code: this.postal_code,
          estado: this.estado,
          municipio: this.municipio,
          colonia: this.colonia
        });
      }
    },
    seleccionarDireccion(direccion) {
      this.$emit('lock', direccion);
      console.log(direccion)
      this.mostrarFormulario = false;
    },
    resetFormFields() {
      this.estados = [];
      this.municipios = [];
      this.colonias = [];
      this.estado = '';
      this.municipio = '';
      this.colonia = '';
    }
  },
  mounted() {
    this.cargarDirecciones();
  }
};
</script>

<style scoped lang="sass">
/* Aquí van los estilos adaptados */
h3
  font-size: 22px
  font-weight: lighter
  background: #8246AF
  padding: 8px 15px
  display: block
  overflow: hidden
  margin-top: 20px
  color: #fff
  border-radius: 8px 8px 0 0
.resumen-compra
  border: 1px solid #ccc
  margin: 20px 0
  background: #fff
  h3 
    margin-top: 0
  form
    padding: 30px
    div
      display: flex
      justify-content: space-between
      margin-bottom: 15px
      label
        width: 30%
        font-weight: bold
        color: #8246AF
      input, select
        width: 70%
        border-radius: 10px
        border: none
        background: #EDEDED
        padding: 0 15px
.tarjeta-direccion
  border: 1px solid #ddd
  border-radius: 10px
  padding: 15px
  margin-bottom: 10px
  background-color: #f9f9f9
button
  background-color: #8246AF
  border: none
  color: white
  padding: 10px 20px
  font-size: 16px
  cursor: pointer
  width: 100%
  border-radius: 30px
button:hover
  background-color: #965ec1
.tarjeta-direccion
  padding: 20px
.int-tarjeta-direccion
  box-shadow: 0px 0px 3pc rgba(155,155,155, 0.4)
  padding: 15px
  font-size: 0.7em
  margin-bottom: 10px
  border-radius: 10px
  button
    display: block
    text-align: center
    max-width: 300px
    margin: 0 auto
</style>
