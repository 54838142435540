<template>
  <div class="terminos-y-condiciones">
    <h1>Términos y Condiciones</h1>
    <p>
      Bienvenido a <strong>XPromo</strong>. Al acceder y utilizar nuestro sitio web, usted acepta cumplir y estar sujeto a los siguientes términos y condiciones, los cuales, junto con nuestra política de privacidad, rigen la relación de XPromo con usted en relación a este sitio web.
    </p>
    <h2>Uso del Sitio Web</h2>
    <p>
      El contenido de las páginas de este sitio web es para su información general y uso personal. Está sujeto a cambios sin previo aviso.
    </p>
    <h2>Productos y Servicios</h2>
    <p>
      Ofrecemos una variedad de productos que pueden ser personalizados, incluyendo termos, libretas y otros artículos. Nos esforzamos por mostrar los colores y las imágenes de nuestros productos con la mayor precisión posible, pero no podemos garantizar que su pantalla muestre los colores de manera exacta.
    </p>
    <h2>Pedidos y Pagos</h2>
    <p>
      Al realizar un pedido, usted se compromete a proporcionar información verdadera y precisa. Nos reservamos el derecho de rechazar cualquier pedido que realice con nosotros. En el caso de que cancelemos un pedido, se le notificará por correo electrónico y se le reembolsará el monto total.
    </p>
    <h2>Personalización de Productos</h2>
    <p>
      Los usuarios pueden cargar imágenes para la personalización de productos. Al cargar una imagen, usted declara y garantiza que tiene el derecho de utilizar la imagen y que la misma no infringe los derechos de propiedad intelectual de terceros. Usted será el único responsable de cualquier reclamación relacionada con la autoría y el uso de las imágenes cargadas.
    </p>
    <h2>Propiedad Intelectual</h2>
    <p>
      Este sitio web contiene material que es propiedad de o licenciado a nosotros. Este material incluye, pero no se limita a, el diseño, el texto, los gráficos y el logotipo. La reproducción está prohibida, excepto de acuerdo con el aviso de copyright, que forma parte de estos términos y condiciones.
    </p>
    <h2>Enlaces a Otros Sitios Web</h2>
    <p>
      Nuestro sitio web puede contener enlaces a otros sitios de interés. Sin embargo, una vez que haya utilizado estos enlaces para salir de nuestro sitio, no tenemos control sobre esos otros sitios web. Por lo tanto, no podemos ser responsables de la protección y privacidad de cualquier información que proporcione mientras visita tales sitios.
    </p>
    <h2>Limitación de Responsabilidad</h2>
    <p>
      En la medida en que lo permita la ley, XPromo no será responsable por cualquier daño indirecto, incidental, especial, consecuente o punitivo, o cualquier pérdida de ingresos, ganancias, ahorros, datos, o costos de adquisición de servicios sustitutivos, incurridos por usted o cualquier tercero, ya sea en una acción contractual o extracontractual, que surja de su acceso o uso de este sitio web.
    </p>
    <h2>Cambios en los Términos y Condiciones</h2>
    <p>
      XPromo se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Le recomendamos que revise esta página periódicamente para estar informado de cualquier cambio. Su uso continuo del sitio web después de cualquier cambio constituye su aceptación de los nuevos términos y condiciones.
    </p>
    <h2>Contacto</h2>
    <p>
      Si tiene alguna pregunta o inquietud sobre estos términos y condiciones, no dude en ponerse en contacto con nosotros en <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TerminosYCondiciones'
}
</script>

<style lang="scss">
 .terminos-y-condiciones {
        padding: 40px 20px;
        max-width: 1200px;
        margin: 0 auto;
        background-color: #f9f9f9;
        border-radius: 8px;
        font-size: 0.8em;
    
        h1 {
        font-size: 2em;
        text-align: center;
        margin-bottom: 10px;
        }
        h2 {
            text-align: center;
            margin: 30px auto 10px auto;
        }
        p {
        font-size: 0.9em;
        text-align: justify;
        line-height: 1.5;
        }
        a {
        color: #007BFF;
        text-decoration: none;

        &:hover {
        text-decoration: underline;
        }
    }
  }

</style>