<template>
  <div>
    <p class="txt-morado txt-bold size-seven mt-10 informacion">
      Cantidad:
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_126_721)">
          <path d="M10.0835 6.41668H11.9168V8.25001H10.0835V6.41668ZM10.0835 10.0833H11.9168V15.5833H10.0835V10.0833ZM11.0002 1.83334C5.94016 1.83334 1.8335 5.94001 1.8335 11C1.8335 16.06 5.94016 20.1667 11.0002 20.1667C16.0602 20.1667 20.1668 16.06 20.1668 11C20.1668 5.94001 16.0602 1.83334 11.0002 1.83334ZM11.0002 18.3333C6.95766 18.3333 3.66683 15.0425 3.66683 11C3.66683 6.95751 6.95766 3.66668 11.0002 3.66668C15.0427 3.66668 18.3335 6.95751 18.3335 11C18.3335 15.0425 15.0427 18.3333 11.0002 18.3333Z" fill="#007DAB"/>
          </g>
          <defs>
          <clipPath id="clip0_126_721">
          <rect width="22" height="22" fill="white"/>
          </clipPath>
          </defs>
        </svg>
    </p>
    <input type="number" id="cantidad" :min="minQuantity" :max="maxQuantity" :value="currentQuantity" @input="debouncedInput" @blur="validateInput(currentQuantity)">
    <p v-if="minQuantity " class="note size-four" style="color: grey;">
      El pedido mínimo para este producto es de {{ minQuantity }} unidades
    </p>
  </div>
</template>

<script>
export default {
  props: {
    minQuantity: Number,
    maxQuantity: Number,
    currentQuantity: Number
  },
  data() {
    return {
      timeout: null,
    };
  },
  methods: {
    debouncedInput(event) {
      clearTimeout(this.timeout);
      const inputValue = event.target.value; // Keep the raw input value

      this.timeout = setTimeout(() => {
        this.validateInput(inputValue);
      }, 1000); // Delay the validation for 1s to allow for complete input
    },
    validateInput(value) {
      let validatedValue = parseInt(value);
      validatedValue = isNaN(validatedValue) ? this.minQuantity : validatedValue; // Default to minQuantity if conversion fails
      validatedValue = Math.max(this.minQuantity, validatedValue);
      validatedValue = this.maxQuantity !== Infinity ? Math.min(this.maxQuantity, validatedValue) : validatedValue;
      this.$emit('update:currentQuantity', validatedValue);
    }
  }
}
</script>
  <style lang="sass" scoped>
.txt-morado
    color: #8246AF

.txt-bold
    font-weight: bold

.size-seven
    font-size: 0.7em

.size-four
    font-size: 0.5em

.mt-10
    margin-top: 10px
.mt-15
    margin-top: 15px
.mt-20
    margin-top: 20px
input[type=number]
    padding: 10px
    margin-top: 5px
    margin-bottom: 0px
    border: 1px solid #ccc
    border-radius: 4px
    width: 100%
    max-width: 230px
    border: none
    background: #EDEDED

.size-four
    font-size: 0.44em
.informacion
    display: flex
    align-items: center
    svg
        transform: scale(0.8)
        cursor: pointer
  </style>