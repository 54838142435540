<template>
    <div v-if="history.length >= 4" class="history-section">
      <p>Sigue viendo</p>
      <div class="history-items">
        <div v-for="item in history.slice(0, 4)" :key="item.id_producto_prov" class="history-item">
          <img :src="item.url_image || item.url_imagen" :alt="item.product_name" @click="goToProduct(item.id_producto_prov)" class="history-image">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CuadroCuatroHistorial',
    data() {
      return {
        history: []
      };
    },
    methods: {
      loadHistory() {
        const historyData = JSON.parse(localStorage.getItem('recentlyViewed') || '[]');
        this.history = historyData.filter(item => item.url_image || item.url_imagen); // Filtra para asegurarse de que solo se muestran elementos con imagen
      },
      goToProduct(idProductoProv) {
        this.$router.push(`/producto/${idProductoProv}`);
      }
    },
    mounted() {
      this.loadHistory();
    }
  }
  </script>
  
  <style lang="sass" scoped>
.history-section
  display: flex
  flex-direction: column
  align-items: center
  padding: 10px
  background: #fff
  margin-bottom: 30px
  border-radius: 10px
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1)
  width: 100%
  @media screen and (min-width: 350px)
    width: 48%
  @media screen and (min-width: 592px)        
    width: 45%
  @media screen and (min-width: 780px)
    width: 30%
  @media screen and (min-width: 992px)
    width: 23%
  p 
    color: #582C83
    padding-bottom: 10px
    font-weight: 400
    font-size: 1em
    @media screen and (min-width: 350px)
      font-size: 0.7em
    @media screen and (min-width: 410px)
      font-size: 0.8em
    @media screen and (min-width: 600px)
      font-size: 0.9em
.history-items
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 10px
  width: 100%
  max-width: 300px
  padding: 10px
  background: #EDEDED
  border-radius: 6px

.history-item
  display: flex
  justify-content: center
  align-items: center
  background: #fff
  cursor: pointer
  aspect-ratio: 1 / 1
  transition: transform 0.3s ease
  border-radius: 5px
  overflow: hidden // Asegura que nada se desborde del contenedor

.history-image
  width: 100%
  height: 100%
  object-fit: contain // Ajusta la imagen dentro del contenedor sin cortarla
  border-radius: 5px

.history-item:hover 
  transform: scale(1.05)

  </style>
  