<template>
    <div class="status-tracker">
      <div class="status-bar">
        <div class="status-indicator" :style="{width: indicatorWidth}"></div>
      </div>
      <div class="status-steps">
        <div v-for="(step, index) in steps" :key="index" class="step">
          <div class="dot" :class="{active: isActive(index)}"></div>
          <div class="label">{{ step }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      steps: {
        type: Array,
        default: () => []
      },
      currentStep: {
        type: Number,
        default: 0
      }
    },
    computed: {
    indicatorWidth() {
      // Define los porcentajes específicos para cada paso
        const percentages = [0, 1, 18, 34, 50, 67, 83, 100];
        return `${percentages[this.currentStep]}%`;
        }
    },
    methods: {
      isActive(index) {
        return index < this.currentStep;
      }
    }
  }
  </script>
  
<style lang="sass" scoped>

.status-tracker 
    width: 100%
    padding: 10px 0
    margin: 20px auto 10px auto
  
  
.status-bar 
    background: #eee
    height: 13px
    position: relative
    max-width: 95%
    
    margin: 0 auto
    border-radius: 15px
  
  
.step 
    max-width: 80px
    min-width: 80px
  
.status-indicator 
    background: #582C83
    height: 13px
    width: 0%
    border-radius: 15px
    transition: width 0.3s ease
  
  
.status-steps 
    display: flex
    justify-content: space-between
    margin-top: 10px
  
  
.dot 
    height: 10px
    width: 10px
    border-radius: 50%
    background: #ccc
    border: 2px solid white
    margin: 0 auto
  
  
.dot.active 
    background: #582C83
  
  
.label 
    text-align: center
    margin-top: 5px
    font-size: 0.5em
  
</style>
  