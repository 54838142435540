<template>
    <div>
      <p class="txt-morado txt-bold size-seven mt-20">
        Color <small style="color:gray; font-weight: 100;">Selecciona un color*</small>
      </p>
      <div class="mt-0" v-if="colors && colors.length > 0">
        <div v-for="(variant, index) in colors" :key="index"
             class="color-circle"
             :style="variant.css + '; border-radius: 50%; border: 2px solid #888; cursor: pointer;' + (selectedColor === variant ? 'border: 3px solid #8246AF;' : '')"
             @click="selectColor(variant)">
          <div v-if="variant.inventario === 0" class="out-of-stock">Sin stock</div>
        </div>
      </div>
      <p v-else class="note size-four" style="color: red;">
        Este producto está temporalmente agotado.
      </p>
      <p class="size-four" v-if="selectedColor">Para esta variante puedes pedir hasta <b>{{ selectedColor.inventario }}</b> unidades</p>
    </div>
</template>

<script>
export default {
    props: {
      colors: Array,
      selectedColor: Object
    },
    emits: ['update:selectedColor'],
    methods: {
      selectColor(variant) {
        this.$emit('update:selectedColor', variant);
      }
    }
};
</script>

<style scoped>
</style>
<style lang="sass" scoped>
.color-circle 
    width: 23px
    height: 23px
    display: inline-block
    margin-right: 5px
    border-radius: 50%
    border: 2px solid #888
    cursor: pointer


.out-of-stock 
    font-size: 0.8em
    color: red
    text-align: center

.txt-morado
    color: #8246AF

.txt-bold
    font-weight: bold

.size-seven
    font-size: 0.7em

.size-four
    font-size: 0.5em

.mt-10
    margin-top: 10px
.mt-15
    margin-top: 15px
.mt-20
    margin-top: 20px
input[type=number]
    padding: 10px
    margin-top: 5px
    margin-bottom: 0px
    border: 1px solid #ccc
    border-radius: 4px
    width: 100%
    max-width: 230px
    border: none
    background: #EDEDED

.size-four
    font-size: 0.44em
.informacion
    display: flex
    align-items: center
    svg
        transform: scale(0.8)
        cursor: pointer
  </style>