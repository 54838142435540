<template>
    <div v-if="favorites.length >= 4" class="favorites-section">
      <p>Favoritos</p>
      <div class="favorite-items">
        <div v-for="item in favorites.slice(0, 4)" :key="item.id_producto_prov" class="favorite-item">
          <img :src="item.url_image || item.url_imagen" :alt="item.product_name" @click="goToProduct(item.id_producto_prov)" class="favorite-image">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FavoritesComponent',
    data() {
      return {
        favorites: []
      };
    },
    methods: {
      loadFavorites() {
        const favoritesData = JSON.parse(localStorage.getItem('favorites') || '{}');
        this.favorites = Object.values(favoritesData).filter(item => item.url_image || item.url_imagen); // Asegúrate de que cada elemento tenga una imagen definida
      },
      goToProduct(idProductoProv) {
        this.$router.push(`/producto/${idProductoProv}`);
      }
    },
    mounted() {
      this.loadFavorites();
    }
  }
  </script>
  
  <style lang="sass" scoped>
  .favorites-section
    display: flex
    flex-direction: column
    align-items: center
    padding: 10px
    background: #fff
    // border: 1px solid #ccc
    margin-bottom: 30px
    border-radius: 5px
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1)
    border-radius: 10px
    width: 100%
    @media screen and (min-width: 350px)
      width: 48%
    @media screen and (min-width: 592px)        
      width: 45%
    @media screen and (min-width: 780px)
      width: 30%
    @media screen and (min-width: 992px)
      width: 23%
    p 
      color: #582C83
      padding-bottom: 10px
      font-weight: 400
      font-size: 1em
      @media screen and (min-width: 350px)
        font-size: 0.7em
      @media screen and (min-width: 410px)
        font-size: 0.8em
      @media screen and (min-width: 600px)
        font-size: 0.9em
  
  .favorite-items
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 10px
    width: 100%
    max-width: 300px // Ajusta esto según tus necesidades
    padding: 10px
    background: #EDEDED
    border-radius: 6px
  
  .favorite-item
    display: flex
    justify-content: center
    align-items: center
    background: #fff
    cursor: pointer
    aspect-ratio: 1 / 1    
    transition: transform 0.3s ease
    border-radius: 5px
  
  .favorite-image
    width: 100%
    border-radius: 5px
  
  .favorite-item:hover 
    transform: scale(1.05)
  </style>
  