<template>
    <div class="politicas-devolucion-reembolso">
      <h1>Políticas de Devolución y Reembolso</h1>
      <p>
        En <strong>XPromo</strong>, nuestra prioridad es la satisfacción de nuestros clientes. Si no está completamente satisfecho con su compra, estamos aquí para ayudarlo. Nuestras políticas de devolución y reembolso están diseñadas para cumplir con las leyes y regulaciones vigentes en México.
      </p>
      <h2>Devoluciones</h2>
      <p>
        Usted tiene el derecho de devolver los productos comprados dentro de los 30 días naturales a partir de la fecha de recepción de los mismos. Para ser elegible para una devolución, el producto debe estar sin usar y en las mismas condiciones en que lo recibió. También debe estar en su empaque original.
      </p>
      <h3>Procedimiento para Devoluciones</h3>
      <p>
        Para iniciar una devolución, por favor siga estos pasos:
      </p>
      <ol>
        <li>Contacte a nuestro equipo de servicio al cliente a través del correo electrónico <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a> proporcionando su número de orden y el motivo de la devolución.</li>
        <li>Recibirá un correo electrónico con las instrucciones para proceder con la devolución.</li>
        <li>Envuelva el producto en su empaque original y asegúrese de incluir todos los accesorios y documentación.</li>
        <li>Envíe el paquete a la dirección proporcionada por nuestro equipo de servicio al cliente.</li>
      </ol>
      <h2>Reembolsos</h2>
      <p>
        Una vez que recibamos su producto devuelto, lo inspeccionaremos y le notificaremos de la aprobación o rechazo de su reembolso. Si se aprueba, se procesará el reembolso a su método original de pago dentro de los 14 días hábiles.
      </p>
      <p>
        Tenga en cuenta que los costos de envío no son reembolsables. Si recibe un reembolso, el costo del envío de la devolución se deducirá de su reembolso.
      </p>
      <h3>Excepciones a las Devoluciones</h3>
      <p>
        Los siguientes productos no son elegibles para devolución:
      </p>
      <ul>
        <li>Productos personalizados, como aquellos con nombres o logotipos específicos.</li>
        <li>Artículos en oferta o con descuento.</li>
      </ul>
      <h2>Productos Defectuosos o Dañados</h2>
      <p>
        Si recibe un producto defectuoso o dañado, contáctenos de inmediato a <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a>. Le solicitaremos fotografías del producto y del empaque para evaluar la situación y proceder con el reemplazo o reembolso según corresponda.
      </p>
      <h2>Legislación Aplicable</h2>
      <p>
        Estas políticas están alineadas con la Ley Federal de Protección al Consumidor de México. Le recomendamos que revise esta ley para conocer sus derechos como consumidor.
      </p>
      <h2>Contacto</h2>
      <p>
        Si tiene alguna pregunta sobre nuestras políticas de devolución y reembolso, no dude en ponerse en contacto con nosotros a través de <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a>.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PoliticasDevolucionReembolso'
  }
  </script>
  
  <style lang="scss" scoped>
  .politicas-devolucion-reembolso {
    padding: 40px 20px;
        max-width: 1200px;
        margin: 0 auto;
        background-color: #f9f9f9;
        border-radius: 8px;
        font-size: 0.8em;
    
        h1 {
        font-size: 2em;
        text-align: center;
        margin-bottom: 10px;
        }
        h2 {
            text-align: center;
            margin: 30px auto 10px auto;
        }
        h3 {
            font-size: 1.4em;
            text-align: center;
            margin: 30px auto 10px auto;
        }
        ul {
            list-style: none;
            // padding-left: 30px;
        }
        ol, ul {
            // list-style: none;
            padding-left: 30px;
        }
        li {
            font-size: 0.8em;
            margin: 10px 0;
        }
        p {
        font-size: 0.9em;
        text-align: justify;
        line-height: 1.5;
        }
        a {
        color: #007BFF;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
  }
  </style>
  