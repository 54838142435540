<template>
    <div class="product-card"  @click="goToProduct(product.id_producto_prov)">
        <span class="favorito" @click.stop="toggleFavorite">
            <svg v-if="isFavorite" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" viewBox="0 0 24 24"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="gray" viewBox="0 0 24 24"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>
        </span>
        <div class="image-container" v-if="product.url_image">            
            <img :src="product.url_image" :alt="product.product_name" />
          </div>
          <div class="colors">
            <div v-for="(color, index) in product.colores" :key="index" class="color-circle" :style="color"></div>
          </div>
          <!-- <h3>{{ product.product_name }}</h3> -->
          <div class="info">
            <p class="description">{{ product.product_name }}</p>
            <p class=" name ">{{ product.description }}</p>
            
            <p class="cost">${{ product.price }}</p>
            <p class="calificacion">Calificacion: 
                    <svg width="112" height="20" viewBox="0 0 112 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 15.5279L16.18 19.2579L14.54 12.2279L20 7.49793L12.81 6.88793L10 0.257935L7.19 6.88793L0 7.49793L5.46 12.2279L3.82 19.2579L10 15.5279Z" fill="#F9A318"/>
                      <path d="M33 15.5279L39.18 19.2579L37.54 12.2279L43 7.49793L35.81 6.88793L33 0.257935L30.19 6.88793L23 7.49793L28.46 12.2279L26.82 19.2579L33 15.5279Z" fill="#F9A318"/>
                      <path d="M56 15.5279L62.18 19.2579L60.54 12.2279L66 7.49793L58.81 6.88793L56 0.257935L53.19 6.88793L46 7.49793L51.46 12.2279L49.82 19.2579L56 15.5279Z" fill="#F9A318"/>
                      <path d="M79 15.5279L85.18 19.2579L83.54 12.2279L89 7.49793L81.81 6.88793L79 0.257935L76.19 6.88793L69 7.49793L74.46 12.2279L72.82 19.2579L79 15.5279Z" fill="#F9A318"/>
                      <path d="M102 15.5279L108.18 19.2579L106.54 12.2279L112 7.49793L104.81 6.88793L102 0.257935L99.19 6.88793L92 7.49793L97.46 12.2279L95.82 19.2579L102 15.5279Z" fill="#F9A318"/>
                  </svg>

            </p>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        isFavorite: false
      };
    },
    methods: {
      goToProduct(idProductoProv) {
        this.$router.push(`/producto/${idProductoProv}`);
      },
      toggleFavorite() {
        this.isFavorite = !this.isFavorite;
        this.updateLocalStorage();
      },
      updateLocalStorage() {
        let favorites = JSON.parse(localStorage.getItem('favorites')) || {};
        if (this.isFavorite) {
          favorites[this.product.id_producto_prov] = this.product;
        } else {
          delete favorites[this.product.id_producto_prov];
        }
        localStorage.setItem('favorites', JSON.stringify(favorites));
      }
    },
    mounted() {
      let favorites = JSON.parse(localStorage.getItem('favorites')) || {};
      this.isFavorite = favorites.hasOwnProperty(this.product.id_producto_prov);
    }
  }
  </script>
  
  
<style lang="sass" scoped>
  .product-card     
    background: #F5F5F5
    margin: 10px 0
    padding: 20px
    border-radius: 15px
    box-shadow: 0 0 30px rgba(155,155,155, 0.6)
    cursor: pointer
    position: relative
    width: 100%
    @media screen and (min-width: 350px)
      width: 48%
    @media screen and (min-width: 592px)
      width: 45%
    @media screen and (min-width: 780px)
      width: 30%
    @media screen and (min-width: 992px)
      width: 23%
    .description
      font-size: 0.5em
      font-weight: bold
      margin: 5px auto 10px 0
      height: 14px
      overflow: hidden
      @media screen and (min-width: 592px)
      @media screen and (min-width: 992px)


    .info
      display: flex
      flex-direction: column
      justify-content: space-between
      // height: 100%      
      
      .name
        // font-size: 0.5em
        // min-height: 68px
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        font-size: 0.5em
        // font-weight: bold
        margin: 5px auto 10px 0
        height: 4em  // Ajusta esta altura según el tamaño de tu fuente y el espacio entre líneas
      .cost
        font-size: 1em
        margin: 10px 0
      .calificacion
        font-size: 0.7em
        display: flex
        align-items: flex-end
        svg 
          margin-left: 10px
    img
      // border-radius: 15px
    .favorito
        position: absolute
        right: 28px

.product-card img 
  max-width: 100%
  height: auto
.colors
  display: flex
  flex-wrap: wrap
  height: 20px
  overflow: hidden
  margin-top: 10px
  .color-circle
    width: 20px
    height: 20px
    border-radius: 50%
    border: 1px solid gray
    margin-right: 5px
    margin-bottom: 5px
.image-container 
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  height: 200px
  background: #fff
  border-radius: 15px


.image-container img 
  max-width: 100%
  max-height: 100%
  object-fit: contain
  </style>
  