<template>
  Restablecer
</template>

<script>
export default {

}
</script>
<style lang="sass">

</style>