<template>
  <div class="buscador">
    <span class="icono-cam" @click="triggerFileInput">
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z" fill="#8246AF"/>
        <path d="M7 0L5.17 2H2C0.9 2 0 2.9 0 4V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V4C20 2.9 19.1 2 18 2H14.83L13 0H7ZM10 15C7.24 15 5 12.76 5 10C5 7.24 7.24 5 10 5C12.76 5 15 7.24 15 10C15 12.76 12.76 15 10 15Z" fill="#8246AF"/>
      </svg>
      <input type="file" ref="fileInput" accept="image/*" style="display: none" @change="handleImage">
    </span>
    <input type="file" ref="fileInput" accept="image/*" style="display: none" @change="handleImage">
    <input type="text" class="buscador-input" v-model="searchTerm" @keydown.enter="performSearch">
    <span class="icono-buscar" @click="performSearch">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7233 13.8365H14.7296L14.3774 13.4969C15.6101 12.0629 16.3522 10.2013 16.3522 8.1761C16.3522 3.66038 12.6918 0 8.1761 0C3.66038 0 0 3.66038 0 8.1761C0 12.6918 3.66038 16.3522 8.1761 16.3522C10.2013 16.3522 12.0629 15.6101 13.4969 14.3774L13.8365 14.7296V15.7233L20.1258 22L22 20.1258L15.7233 13.8365ZM8.1761 13.8365C5.04402 13.8365 2.51572 11.3082 2.51572 8.1761C2.51572 5.04402 5.04402 2.51572 8.1761 2.51572C11.3082 2.51572 13.8365 5.04402 13.8365 8.1761C13.8365 11.3082 11.3082 13.8365 8.1761 13.8365Z" fill="#3C1053"/>
      </svg>
    </span>
    <!-- Modal para procesar la imagen -->
    <transition name="fade">
      <div v-if="showModal" class="modal-overlay">
        <div class="modal">
          <transition name="fade">  

            <div class="caja-img">
              <img :src="originalImage" alt="Original Image" class="base-image">
              <transition name="fade">
                <img v-if="processedImage" :src="processedImage" :key="currentImageKey"
                     :class="{'image-transition': true, 'persist-opacity': imageLoaded}"
                     alt="Processed Image">
              </transition>
            </div>
          </transition>
          <p>{{ modalMessage }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      searchTerm: '',
      showModal: false,
      modalMessage: '',
      originalImage: '',
      processedImage: null,
      currentImageKey: 0,
      imageLoaded: false,
      messages: ['','Procesando imagen', 'Buscando productos similares', 'Mostrando productos'],
      currentMessageIndex: 0
    };
  },
  // mounted() {
  //   this.typeMessage();
  // },
  methods: {
    ...mapActions(['setImageSearchResults', 'clearImageSearchResults']),
    triggerFileInput() {
      // alert('Triggered file input'); // Alert para depurar
      this.$refs.fileInput.click();
    },
   
  handleText(index) {
    this.clearCurrentInterval(); // Limpia el intervalo actual antes de comenzar otro
    this.eraseMessage(() => {
      this.typeNextMessage(index);
    });
  },

  clearCurrentInterval() {
    if (this.currentInterval) {
      clearInterval(this.currentInterval);
      this.currentInterval = null;
    }
  },

  eraseMessage(callback) {
    let i = this.modalMessage.length;
    this.currentInterval = setInterval(() => {
      if (i > 0) {
        this.modalMessage = this.modalMessage.substring(0, i - 1);
        i--;
      } else {
        this.clearCurrentInterval();
        if (callback) callback(); // Ejecuta el callback después de borrar
      }
    }, 15);
  },

  typeNextMessage(index) {
    const messages = ['','Procesando imagen', 'Buscando productos similares', 'Mostrando productos'];
    let i = 0;
    this.currentInterval = setInterval(() => {
      if (i < messages[index].length) {
        this.modalMessage += messages[index].charAt(i);
        i++;
      } else {
        this.clearCurrentInterval();
      }
    }, 30);
  },
    async handleImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.showModal = true;
        // this.modalMessage = 'Procesando imagen';
        this.originalImage = URL.createObjectURL(file);  // Guarda la imagen original

           // Inicia la animación de texto con el primer mensaje
        this.handleText(1);
        const formData = new FormData();
        formData.append('file', file);

        try {
          // Primero, limpia la imagen
          setTimeout(() => {
            this.handleText(2);            
          }, 3000);
          const cleanResponse = await fetch('/api/clean_image', {
            method: 'POST',
            body: formData,
          });

          if (!cleanResponse.ok) {
            const errorText = await cleanResponse.text();
            throw new Error(`Failed to clean the image: ${errorText}`);
          }
          // Continúa con la segunda animación de texto
          
          setTimeout(() => {
            this.handleText(3);
          }, 1500);
          // Recibe la imagen limpia en forma de blob
          const cleanBlob = await cleanResponse.blob();
          // this.modalMessage = 'Buscando productos similares';
          this.processedImage = URL.createObjectURL(cleanBlob);  // Guarda la imagen procesada
          this.currentImageKey++;  // Incrementa para forzar la transición         
          setTimeout(() => {
            // this.showModal = false;  // Oculta el modal
            this.imageLoaded = true; 
          }, 300);
          const cleanFormData = new FormData();
          cleanFormData.append('file', cleanBlob, file.name);  // Asegúrate de usar el mismo nombre de archivo

          // Luego, sube la imagen limpia
          const uploadResponse = await fetch('/api/upload_image', {
            method: 'POST',
            body: cleanFormData,
          });

          if (!uploadResponse.ok) {
            const errorText = await uploadResponse.text();
            throw new Error(`Failed to upload the cleaned image: ${errorText}`);
          }
          // this.modalMessage = 'Mostrando productos';
           // Finaliza con la tercera animación de texto
          

          const similarImages = await uploadResponse.json();
          setTimeout(() => {
            this.showModal = false;  // Oculta el modal
            setTimeout(() => {
              this.originalImage = '';  // Oculta el modal
              this.processedImage = null;  // Oculta el modal
              this.imageLoaded = false;  // Oculta el modal
            }, 100);
            this.handleText(0);
          }, 100);
          this.setImageSearchResults(similarImages);
          this.$router.push({ name: 'SearchResults', query: { searchTerm: 'busqueda-inteligente' } });

        } catch (error) {
          console.error('Error processing the image:', error);
        }
      }
    },

    performSearch() {
      if (this.searchTerm.length >= 3) {
        // Limpia los resultados de búsqueda de imagen
        // // alert('Performing search'); // Alert para depurar
        this.clearImageSearchResults();
        this.$router.push({ name: 'SearchResults', query: { searchTerm: this.searchTerm } });
        
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.buscador
  display: flex
  align-items: center
  justify-content: center
  position: relative
  width: 65%
  max-width: 522px
  @media screen and (min-width: 1200px)
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: auto
  .icono-cam			
    position: absolute
    z-index: 2
    top: 0
    bottom: 0
    left: 20px
    right: auto
    margin: auto
    display: flex
    align-items: center
    cursor: pointer
  .buscador-input                
    border: none
    border-radius: 30px
    width: 100%
    max-width: 522px
    padding-left: 50px
    margin-top: 0
    margin-bottom: 0
    height: 35px
    @media screen and (min-width: 768px) 
      height: 45px
  .icono-buscar			
    position: absolute
    z-index: 2
    top: 0
    bottom: 0
    right: 20px
    left: auto
    margin: auto
    display: flex
    align-items: center
    cursor: pointer

.modal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  // margin: auto
  background-color: rgba(0, 0, 0, 0.7)
  display: flex
  justify-content: center
  align-items: center
  z-index: 3
  height: 100vh
  width: 100vw

.modal
  background-color: white
  padding: 20px
  border-radius: 10px
  text-align: center
  max-width: 400px  
  width: 90%
  .caja-img
    position: relative
    max-width: 360px
    width: 100%
    height: 360px
    overflow: hidden
    .base-image
      position: absolute
      top: 0
      left: 0
      max-width: 100%
      
      height: 100%
      right: 0
      margin: auto
      object-fit: contain
      border-radius: 15px
.image-transition
  position: absolute
  top: 0
  left: 0
  max-width: 100%
  height: 100%
  object-fit: cover
  border-radius: 15px
  transition: opacity 0.5s ease-in-out
  opacity: 0  // Inicia invisible
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s
.fade-enter, .fade-leave-to
  opacity: 0
.fade-enter-to
  opacity: 1
.persist-opacity 
  opacity: 1 !important  // Uso de !important para asegurar que la opacidad se mantenga

p
  margin-top: 10px
  color: #3C1053
  font-weight: bold
  min-height: 34px

</style>
