import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state: {
    user: null,
    isAuthenticated: false,
    cart: [],
    imageSearchResults: [], // Agregamos el estado para los resultados de búsqueda de imágenes
    isLoading: false,
    statusMessage: '',
    imagePreviewUrl: ''
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    username(state) {
      return state.user ? state.user.username : '';
    },
    cartItems(state) {
      return state.cart;
    },
    cartTotal(state) {
      return state.cart.reduce((total, item) => total + item.price * item.quantity, 0);
    },
    imageSearchResults(state) {
      return state.imageSearchResults; // Agregamos el getter para los resultados de búsqueda de imágenes
    }
  },
  mutations: {
    setUser(state, userData) {
      console.log('Setting user:', userData);
      state.user = userData;
      state.isAuthenticated = true;
    },
    clearUser(state) {
      console.log('Clearing user');
      state.user = null;
      state.isAuthenticated = false;
    },
    addToCart(state, product) {
      console.log('Adding to cart:', product);
      const existingProduct = state.cart.find(item => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        state.cart.push({ ...product });
      }
      console.log('Current cart after adding:', JSON.parse(JSON.stringify(state.cart)));
    },
    updateCart(state, updatedProduct) {
      const productIndex = state.cart.findIndex(item => item.uniqueId === updatedProduct.uniqueId);
      if (productIndex !== -1) {
        state.cart.splice(productIndex, 1, updatedProduct); // Reemplaza el producto en el índice encontrado
      } else {
        console.error('Producto no encontrado en el carrito para actualizar');
      }
    },
    removeFromCart(state, productId) {
      state.cart = state.cart.filter(item => item.id !== productId);
    },
    updateQuantity(state, { productId, quantity }) {
      const product = state.cart.find(item => item.id === productId);
      if (product) {
        product.quantity = quantity;
      }
    },
    clearCart(state) {
      state.cart = [];
    },
    setImageSearchResults(state, results) {
      state.imageSearchResults = results; // Mutación para actualizar los resultados de búsqueda de imágenes
    },
    clearImageSearchResults(state) {
      state.imageSearchResults = []; // Mutación para limpiar los resultados de búsqueda de imágenes
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_STATUS_MESSAGE(state, payload) {
      state.statusMessage = payload;
    },
    SET_IMAGE_PREVIEW_URL(state, payload) {
      state.imagePreviewUrl = payload;
    }
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
    },
    logout({ commit }, router) {
      commit('clearUser');
      router.push('/iniciar-sesion');
    },
    addToCart({ commit }, product) {
      commit('addToCart', product);
    },
    removeFromCart({ commit }, productId) {
      commit('removeFromCart', productId);
    },
    updateQuantity({ commit }, payload) {
      commit('updateQuantity', payload);
    },
    clearCart({ commit }) {
      commit('clearCart');
    },
    setImageSearchResults({ commit }, results) {
      commit('setImageSearchResults', results); // Acción para actualizar los resultados de búsqueda de imágenes
    },
    clearImageSearchResults({ commit }) {
      commit('clearImageSearchResults'); // Acción para limpiar los resultados de búsqueda de imágenes
    },
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload);
    },
    setStatusMessage({ commit }, payload) {
      commit('SET_STATUS_MESSAGE', payload);
    },
    setImagePreviewUrl({ commit }, payload) {
      commit('SET_IMAGE_PREVIEW_URL', payload);
    }
  }
});
