import { createRouter, createWebHistory } from "vue-router";
import store from '../store/index.js'; 
import HomeView from "../views/HomeView.vue";
import Productos from '../views/public/Productos.vue';
import DetalleProducto from '../views/public/DetalleProducto.vue';
import MiCarrito from '../views/shopcar/MiCarrito.vue';
import EstadoPedidos from '../views/protecteduser/EstadoPedidos.vue';
import IniciarSesion from '../views/public/IniciarSesion.vue';
import Registrarse from '../views/public/Registrarse.vue';
import CondicionesDeUso from '../views/public/legales/CondicionesDeUso.vue';
import RestablecerAcceso from '../views/public/RestablecerAcceso.vue';
import SearchResults from '../components/search/SearchResults.vue';
import Producto from '../views/public/producto/Producto.vue';
import NotFound from '../views/NotFound.vue';
import AvisoDePrivacidad from '@/views/public/legales/AvisoDePrivacidad.vue'
import MetodosDePago from '@/views/public/legales/MetodosDePago.vue'
import PoliticasDevolucionReembolso from '@/views/public/legales/PoliticasDevolucionReembolso.vue'
import PoliticasEnvios from '@/views/public/legales/PoliticasEnvios.vue'
import PreguntasFrecuentes from '@/views/public/faq/PreguntasFrecuentes.vue'
import AcercaDe from '@/views/public/AcercaDe.vue'
import Contacto from '@/views/public/Contacto.vue'

import Terminados from '../views/Terminados/Terminados.vue';
import PadPrinting from '../views/Terminados/PadPrinting.vue';
import ScreenPrinting from '../views/Terminados/ScreenPrinting.vue';
import PrintingDtf from '../views/Terminados/PrintingDtf.vue';
import Sublimation from '../views/Terminados/Sublimation.vue';
import Embroidery from '../views/Terminados/Embroidery.vue';
import LaserCutting from '../views/Terminados/LaserCutting.vue';
import LaserEngraving from '../views/Terminados/LaserEngraving.vue';
import DigitalPrinting from '../views/Terminados/DigitalPrinting.vue';
import UVPrinting from '../views/Terminados/UVPrinting.vue';
import Containers from '../views/Terminados/Containers.vue';
import Awards from '../views/Terminados/Awards.vue';
import Developments from '../views/Terminados/Developments.vue';
import Favoritos from '../views/favoritos/Favoritos.vue';

import PagoPedido from '../views/protecteduser/PagoPedido.vue';

const routes = [
  { path: "/", name: "home", component: HomeView,},
  { path: '/producto/:id', name: 'Producto', component: Producto,props: (route) => ({ productId: route.params.id })},
  { path: '/producto', redirect: '/productos'},
  { path: '/aviso-de-privacidad', name: 'AvisoDePrivacidad', component: AvisoDePrivacidad},
  { path: '/metodos-de-pago', name: 'MetodosDePago', component: MetodosDePago},
  { path: '/centro-de-ayuda', name: 'PreguntasFrecuentes', component: PreguntasFrecuentes},
  { path: '/politicas-devolucion-reembolso', name: 'PoliticasDevolucionReembolso', component: PoliticasDevolucionReembolso},
  { path: '/politicas-envio', name: 'PoliticasEnvios', component: PoliticasEnvios},
  { path: '/acerca-de-xpromo', name: 'AcercaDe', component: AcercaDe},
  { path: '/contacto', name: 'Contacto', component: Contacto },
  
  { path: '/mis-favoritos', name: 'Favoritos', component: Favoritos },

  { path: '/pagar-pedido/:tokenpedido', name: 'PagoPedido', component: PagoPedido, props: true },

  { path: '/productos/:busquedaString?', name: 'Productos', component: Productos, props: true },
  { path: '/detalle-producto/:id', name: 'DetalleProducto', component: DetalleProducto, props: true },
  { path: '/mi-carrito', name: 'MiCarrito', component: MiCarrito },
  { path: '/estado-pedidos', name: 'EstadoPedidos', component: EstadoPedidos },
  { path: '/iniciar-sesion', name: 'IniciarSesion', component: IniciarSesion },
  { path: '/registrarse', name: 'Registrarse', component: Registrarse },
  { path: '/condiciones-de-uso', name: 'CondicionesDeUso', component: CondicionesDeUso },  
  { path: '/:catchAll(.*)', name: 'NotFound', component: NotFound },
  { 
    path: '/restablecer-acceso', 
    component: RestablecerAcceso,
    beforeEnter: (to, from, next) => {
      if (!store.state.enlaceRestablecerAccesoClickeado) {
        next('/iniciar-sesion');
      } else {
        next();
      }
    } 
  },
  { path: '/search', name: 'SearchResults', component: SearchResults },

  { path: '/terminados', name: 'Terminados', component: Terminados },
  { path: '/terminados/pad-printing', name: 'PadPrinting', component: PadPrinting },
  { path: '/terminados/screen-printing', name: 'ScreenPrinting', component: ScreenPrinting },
  { path: '/terminados/dtf-printing', name: 'PrintingDtf', component: PrintingDtf },
  { path: '/terminados/sublimation', name: 'Sublimation', component: Sublimation },
  { path: '/terminados/embroidery', name: 'Embroidery', component: Embroidery },
  { path: '/terminados/laser-cutting', name: 'LaserCutting', component: LaserCutting },
  { path: '/terminados/laser-engraving', name: 'LaserEngraving', component: LaserEngraving },
  { path: '/terminados/digital-printing', name: 'DigitalPrinting', component: DigitalPrinting },
  { path: '/terminados/uv-printing', name: 'UVPrinting', component: UVPrinting },
  { path: '/terminados/containers', name: 'Containers', component: Containers },
  { path: '/terminados/awards', name: 'Awards', component: Awards },
  { path: '/terminados/developments', name: 'Developments', component: Developments }
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // terminados
});

export default router;
