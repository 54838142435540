<template>
    <div class="aviso-de-privacidad">
      <h1>Aviso de Privacidad</h1>
      <p>
        En <strong>XPromo</strong>, nos comprometemos a proteger la privacidad de nuestros clientes y usuarios. Este aviso de privacidad tiene como objetivo informarle sobre las prácticas que utilizamos para recopilar, usar y proteger su información personal.
      </p>
      <h2>Recopilación de Información</h2>
      <p>
        Recopilamos información personal que usted nos proporciona al realizar una compra, registrarse en nuestro sitio web, suscribirse a nuestro boletín o ponerse en contacto con nosotros. La información puede incluir su nombre, dirección de correo electrónico, dirección de envío, número de teléfono y detalles de pago.
      </p>
      <h2>Uso de la Información</h2>
      <p>
        Utilizamos la información recopilada para procesar sus pedidos, personalizar su experiencia en nuestro sitio web, mejorar nuestro servicio al cliente y comunicarnos con usted sobre promociones y ofertas especiales.
      </p>
      <h2>Protección de la Información</h2>
      <p>
        Implementamos medidas de seguridad adecuadas para proteger su información personal contra accesos no autorizados, alteraciones, divulgaciones o destrucciones. Sin embargo, ningún método de transmisión por Internet o almacenamiento electrónico es 100% seguro, por lo que no podemos garantizar una seguridad absoluta.
      </p>
      <h2>Divulgación a Terceros</h2>
      <p>
        No vendemos, intercambiamos ni transferimos su información personal a terceros, excepto cuando sea necesario para completar una transacción, como con proveedores de servicios de pago y envío. También podemos divulgar información cuando sea requerido por ley o para proteger nuestros derechos.
      </p>
      <h2>Cookies</h2>
      <p>
        Utilizamos cookies para mejorar su experiencia en nuestro sitio web, analizar el tráfico y mostrar contenido personalizado. Usted puede optar por deshabilitar las cookies a través de la configuración de su navegador, pero esto puede afectar la funcionalidad del sitio.
      </p>
      <h2>Derechos del Usuario</h2>
      <p>
        Usted tiene derecho a acceder, rectificar y eliminar su información personal, así como a oponerse al tratamiento de la misma. Para ejercer estos derechos, puede ponerse en contacto con nosotros a través del correo electrónico <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a>.
      </p>
      <h2>Contacto</h2>
      <p>
        Si tiene alguna pregunta o inquietud sobre este aviso de privacidad, no dude en ponerse en contacto con nosotros en <a href="mailto:contacto@xpromo.mx">contacto@xpromo.mx</a>.
      </p>
      <p>
        Este aviso de privacidad puede actualizarse ocasionalmente para reflejar cambios en nuestras prácticas o en la legislación aplicable. Le recomendamos que revise esta página periódicamente para estar informado sobre cómo protegemos su información.
      </p>
    </div>
  </template>
  
  
  
  <script>
  export default {
    name: 'AvisoDePrivacidad'
  }
  </script>
  
  <style lang="scss" scoped>
  .aviso-de-privacidad {
        padding: 40px 20px;
        max-width: 1200px;
        margin: 0 auto;
        background-color: #f9f9f9;
        border-radius: 8px;
        font-size: 0.8em;
    
        h1 {
        font-size: 2em;
        text-align: center;
        margin-bottom: 10px;
        }
        h2 {
            text-align: center;
            margin: 30px auto 10px auto;
        }
        p {
        font-size: 0.9em;
        text-align: justify;
        line-height: 1.5;
        }
        a {
        color: #007BFF;
        text-decoration: none;

        &:hover {
        text-decoration: underline;
        }
    }
  }
  </style>
  