<template>
    <div class="categorias">
        <div class="titulo">
            <h4>CATEGORÍAS</h4>
        </div>
        <div class="slider-container"
            @mousedown="handleTouchStart"
            @mouseup="handleTouchEnd"
            @mouseleave="handleTouchEnd"
            ref="sliderContainer"
        >
        <button @click="prevSlide" class="slider-button left"><svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 2.82L4.96491 12L13 21.18L10.5263 24L8.58137e-07 12L10.5263 -3.59847e-06L13 2.82Z" fill="#3C1053"/>
    </svg>
    </button>
        <div class="slider-window">
            <div class="slider-content" 
                :style="{ transform: `translateX(-${currentIndex * 100}%)`, transition: transitionStyle }"
                @mousemove="handleTouchMove"
            >
            <div class="slide-page" v-for="page in pages" :key="page.start">
                <div class="slide" v-for="category in page.categories" :key="category.id">
                <div class="category-content">
                    <div class="img">

                        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="34" cy="34" r="34" fill="#D9D9D9"/>
                        </svg>
                        <img :src="'https://backoffice.xpromo.mx/img/categoriasprov/'+category.imagen" :alt="category.category_name">
                    </div>
                    <div class="categoria">
                        {{ category.category_name }}
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <button @click="nextSlide" class="slider-button right"><svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.87294e-10 21.18L8.03509 12L0 2.82L2.47368 0L13 12L2.47368 24L4.87294e-10 21.18Z" fill="#3C1053"/>
            </svg>
        </button>
        </div>
        <div class="slider-pagination">
        <span v-for="(page, index) in pages"
                :class="{ 'active': currentIndex === index }"
                @click="goToPage(index)"
                class="dot"
                :key="index"></span>
        </div>

    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, onMounted, computed, watch, onBeforeMount } from 'vue';
  
  export default {
    setup() {
      const slides = ref([]);
      const dataReady = ref(false);
      const currentIndex = ref(0);
      const startX = ref(0); // Inicializando startX como una referencia reactiva
      const isDragging = ref(false);
      const transitionStyle = ref('0.3s ease-in-out');
      const sliderContainer = ref(null);
      const itemsPerPage = ref(8); // Default items per page

       // Aquí definimos la función goToPage
    function goToPage(index) {
      currentIndex.value = index;  // Cambia la página actual a la indexada por el usuario
    }

  
      function adjustItemsPerPage() {
        const width = window.innerWidth;
        if (width < 576) {
          itemsPerPage.value = 2;  // Extra small devices
        } else if (width >= 576 && width < 768) {
          itemsPerPage.value = 4;  // Small devices
        } else if (width >= 768 && width < 1200) {
          itemsPerPage.value = 6;  // Medium devices
        } else {
          itemsPerPage.value = 8;  // Large devices and up
        }
      }


      async function fetchData() {
        const cache = localStorage.getItem('categories');
        if (cache) {
          const parsedCache = JSON.parse(cache);
          if (new Date().getTime() - parsedCache.timestamp < 86400000) {
            slides.value = parsedCache.data;
            return;
          }
        }
        try {
          const response = await axios.get('/api/categorias');
          slides.value = response.data.map(item => ({
            id_token_category: item.id_token_category,
            category_name: item.category_name,
            imagen: item.imagen
          }));
          localStorage.setItem('categories', JSON.stringify({ data: slides.value, timestamp: new Date().getTime() }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
  
      onBeforeMount(adjustItemsPerPage);
      onMounted(() => {
        fetchData();
        window.addEventListener('resize', adjustItemsPerPage);
      });
  
      const pages = computed(() => {
        let pages = [];
        for (let i = 0; i < slides.value.length; i += itemsPerPage.value) {
          pages.push({ start: i, categories: slides.value.slice(i, i + itemsPerPage.value) });
        }
        return pages;
      });
  
      watch(() => itemsPerPage.value, () => {
        currentIndex.value = 0;  // Reset to the first page when items per page changes
      });
  
      function nextSlide() {
        if (currentIndex.value < pages.value.length - 1) {
          currentIndex.value++;
        } else {
          currentIndex.value = 0; // Wrap around
        }
      }
  
      function prevSlide() {
        if (currentIndex.value > 0) {
          currentIndex.value--;
        } else {
          currentIndex.value = pages.value.length - 1; // Wrap around
        }
      }
  
      function handleTouchStart(evt) {
        startX.value = evt.clientX;
        isDragging.value = true;
        transitionStyle.value = 'none'; // Disable transition for smoother dragging
      }
  
      function handleTouchMove(evt) {
        if (isDragging.value) {
          const currentX = evt.clientX;
          const diffX = currentX - startX.value;
          const threshold = sliderContainer.value.clientWidth * 0.2; // 30% of the slider's width
  
          if (Math.abs(diffX) > threshold) {
            if (diffX > 0) {
              prevSlide();
            } else {
              nextSlide();
            }
            handleTouchEnd(); // Reset after action
          }
        }
      }
  
      function handleTouchEnd() {
        isDragging.value = false;
        transitionStyle.value = '0.3s ease-in-out'; // Re-enable transition
      }
  
      return {
        goToPage,
        nextSlide,
        prevSlide,
        pages,
        currentIndex,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
        transitionStyle,
        dataReady,
        sliderContainer
      };
    }
  };
  </script>
  
  <style scoped>
.categorias {
 margin: 40px 0 30px 0;
 user-select: none; 
  }
.slider-container {
  position: relative;
  width: 100%;
  max-width: 1400px;
  /* overflow: hidden; */
  margin: auto;
}

.slider-window {
  overflow: hidden;
}

.slider-content {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.slide-page {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  padding: 0 20px;
}

/* Base styles for slides - adjust based on smallest size */
.slide {
  width: 100%; /* Default to 2 columns for extra small devices */
  box-sizing: border-box;
  padding: 10px;
}

.category-content {
    cursor: pointer;
    text-align: center;
    background: white;
    padding: 10px ;
    text-transform: uppercase;
    font-size: 12px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.category-content .img {
  margin-right: 30px;
  position: relative;
}
.category-content .img img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width: 60px;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
}

.slider-button.left {
  left: 10px;
}

.slider-button.right {
  right: 10px;
}

/* Media queries for responsive breakpoints */
@media (min-width: 576px) {
  .slide {
    width: 50%; /* 4 columns for small devices */
  }
}

@media (min-width: 768px) {
  .slide {
    width: 33%; /* 6 columns for medium devices */
  }
}

@media (min-width: 1200px) {
  .slide {
    width: 25%; /* 8 columns for large devices and up */
  }
}

@media (min-width: 1500px) {
    .slider-container{
        width: 1420px;
        max-width: inherit;
        left: -10px;
    }
    .slide-page {
    
        padding: 0;
    }
    .slider-button.left {
    left: -20px;
    }

    .slider-button.right {
    right: -20px;
    }
}
.titulo h4 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter!important;
    text-align: center;
    color: #5A2E85;
}


.slider-pagination {
  text-align: center;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #3D1053;
}

</style>

  