<template>
    <div class="products-section">
      <p>Lo más vendido</p>
      <div class="product-items">
        <div v-for="item in displayedProducts" :key="item.id_producto_prov || 'placeholder'" class="product-item">
          <img v-if="item.url_image || item.url_imagen" :src="item.url_image || item.url_imagen" :alt="item.product_name" @click="goToProduct(item.id_producto_prov)" class="product-image">
          <div v-else class="placeholder"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'RandomProductsComponent',
    data() {
      return {
        products: [],
        loading: true
      };
    },
    computed: {
      displayedProducts() {
        return this.products.length ? this.products.slice(0, 4) : Array(4).fill({});
      }
    },
    methods: {
      fetchProducts() {
        axios.get('/api/random-products')
          .then(response => {
            this.products = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.error('Error fetching products:', error);
            this.loading = false;
          });
      },
      goToProduct(idProductoProv) {
        if(idProductoProv) {
          this.$router.push(`/producto/${idProductoProv}`);
        }
      }
    },
    mounted() {
      this.fetchProducts();
    }
  }
  </script>
  
  <style lang="sass" scoped>
  .products-section
    display: flex
    flex-direction: column
    align-items: center
    padding: 10px
    margin-bottom: 30px
    background: #fff
    border-radius: 10px
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1)
    width: 100%
    @media screen and (min-width: 350px)
      width: 48%
    @media screen and (min-width: 592px)        
      width: 45%
    @media screen and (min-width: 780px)
      width: 30%
    @media screen and (min-width: 992px)
      width: 23%
    p 
      color: #582C83
      padding-bottom: 10px
      font-weight: 400
      font-size: 1em
      @media screen and (min-width: 350px)
        font-size: 0.7em
      @media screen and (min-width: 410px)
        font-size: 0.8em
      @media screen and (min-width: 600px)
        font-size: 0.9em
  
  .product-items
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 10px
    width: 100%
    max-width: 300px
    padding: 10px
    background: #EDEDED
    border-radius: 6px
  
  .product-item
    display: flex
    justify-content: center
    align-items: center
    background: #fff
    cursor: pointer
    aspect-ratio: 1 / 1
    transition: transform 0.3s ease
    border-radius: 5px
    overflow: hidden
  
  .placeholder
    width: 100%
    height: 100%
    background: #fff
    display: flex
    align-items: center
    justify-content: center
  
  .product-image
    width: 100%
    height: 100%
    object-fit: contain
    border-radius: 5px
  
  .product-item:hover 
    transform: scale(1.05)
  </style>
  