<template>
    <div v-if="isAuthenticated">
        <div class="sesion">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.1016 2.1375C11.529 2.1375 12.6856 3.195 12.6856 4.5C12.6856 5.805 11.529 6.8625 10.1016 6.8625C8.67427 6.8625 7.51762 5.805 7.51762 4.5C7.51762 3.195 8.67427 2.1375 10.1016 2.1375ZM10.1016 12.2625C13.7561 12.2625 17.6075 13.905 17.6075 14.625V15.8625H2.59572V14.625C2.59572 13.905 6.44711 12.2625 10.1016 12.2625ZM10.1016 0C7.38227 0 5.17972 2.01375 5.17972 4.5C5.17972 6.98625 7.38227 9 10.1016 9C12.821 9 15.0235 6.98625 15.0235 4.5C15.0235 2.01375 12.821 0 10.1016 0ZM10.1016 10.125C6.81625 10.125 0.257812 11.6325 0.257812 14.625V18H19.9454V14.625C19.9454 11.6325 13.387 10.125 10.1016 10.125Z" fill="white"/>
            </svg>
            <p style="margin: 0 10px;">Hola {{ username }}</p>
            
            <button @click="logout">Cerrar sesión</button>
        </div>
    </div>
    <div v-else class="sesion iniciar" @click="redirectToLogin">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1016 2.1375C11.529 2.1375 12.6856 3.195 12.6856 4.5C12.6856 5.805 11.529 6.8625 10.1016 6.8625C8.67427 6.8625 7.51762 5.805 7.51762 4.5C7.51762 3.195 8.67427 2.1375 10.1016 2.1375ZM10.1016 12.2625C13.7561 12.2625 17.6075 13.905 17.6075 14.625V15.8625H2.59572V14.625C2.59572 13.905 6.44711 12.2625 10.1016 12.2625ZM10.1016 0C7.38227 0 5.17972 2.01375 5.17972 4.5C5.17972 6.98625 7.38227 9 10.1016 9C12.821 9 15.0235 6.98625 15.0235 4.5C15.0235 2.01375 12.821 0 10.1016 0ZM10.1016 10.125C6.81625 10.125 0.257812 11.6325 0.257812 14.625V18H19.9454V14.625C19.9454 11.6325 13.387 10.125 10.1016 10.125Z" fill="white"/>
        </svg>
        <p>Iniciar sesión</p>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name: 'Sesion',
    computed: {
        ...mapState(['username', 'isAuthenticated']),
        ...mapGetters(['username'])  // Asegúrate de importar mapGetters
    },
    
    methods: {
        ...mapActions(['logout']),    
        redirectToLogin() {
            this.$router.push('/iniciar-sesion');
        }
    }
}
</script>

<style lang="sass">
.sesion
    display: flex
    align-items: center    
    font-size: 0.5em
    color: #fff
    cursor: pointer
    @media screen and (min-width: 589px) 
        margin-right: 1.5rem
    svg
        margin-left: 0.5rem
        @media screen and (min-width: 589px) 
            margin-right: 0.5rem
    button
        background: none
        border: none
        color: #fff
        cursor: pointer
.sesion.iniciar
    p
        display: none
        @media screen and (min-width: 589px) 
            display: block
</style>
