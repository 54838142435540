<template>
    <div class="enlaces">
      <div class="submenu" @mouseover="showMenu = true" @mouseleave="showMenu = false" @click="toggleMenu">
        <span class="nombre-grupo">Categorías</span>
        <span class="flecha">
          <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.75747 -4.2427e-07L6.3594 3.7085L10.9613 -5.64893e-08L12.375 1.1417L6.3594 6L0.343807 1.1417L1.75747 -4.2427e-07Z" fill="white"/>
          </svg>
        </span>
        <div v-if="showMenu" class="menu">
          <a v-for="category in categories" :key="category.id_token_category" :href="'/categoria/' + category.id_token_category">
            {{ category.category_name }}
          </a>
        </div>
      </div>
      <a class="enlace-top" href="/">Ofertas</a>
      <a class="enlace-top" href="/">Historial</a>
      <a class="enlace-top" href="/">Tendencias</a>
      <a class="enlace-top" href="/">Temporalidades</a>
      <a class="enlace-top" href="/">Más vendidos</a>
      <a class="enlace-top" href="/">Novedades</a>
      <a class="enlace-top" href="/terminados">Terminados</a>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'EnlacesBottom',
    data() {
      return {
        categories: [],
        showMenu: false
      };
    },
    methods: {
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      async fetchCategories() {
        let localData = localStorage.getItem('categories');
        if (localData) {
          localData = JSON.parse(localData);
          // Check if the data is still valid
          if (new Date().getTime() - localData.timestamp < 86400000) {
            this.categories = localData.data;
            return;
          }
        }
        try {
          const response = await axios.get('/api/categorias');
          this.categories = response.data;
          localStorage.setItem('categories', JSON.stringify({ data: this.categories, timestamp: new Date().getTime() }));
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      }
    },
    mounted() {
      this.fetchCategories();
    }
  }
  </script>
  
  <style lang="sass" scoped>
  .enlaces
    align-items: center
    justify-content: space-between    
    display: none
    @media screen and (min-width: 780px)
        display: flex
    .submenu
      position: relative
      .nombre-grupo, .flecha
        cursor: pointer
        color: #fff
      .menu
        position: absolute
        background: #fff
        box-shadow: 0 8px 16px rgba(0,0,0,0.2)
        display: flex
        flex-direction: column
        padding: 10px
        top: 100%
        left: 0
        width: 100%
        min-width: 200px
        z-index: 1000
        max-height: 400px
        overflow-y: scroll
        a
          color: #000
          text-decoration: none
          padding: 5px 10px          
          @media screen and (min-width: 1200px)
          &:hover
            background-color: #f0f0f0
  
  .enlace-top, .submenu
    color: #fff
    text-decoration: none
    margin: 0 10px
    font-size: 0.75em
    @media screen and (min-width: 830px)
        font-size: 0.8em
    @media screen and (min-width: 900px)
        font-size: 0.9em
    @media screen and (min-width: 1025px)
        font-size: 1em
    @media screen and (min-width: 1300px)
        margin: 0 14px
    @media screen and (min-width: 1400px)
        margin: 0 18px
  </style>
  